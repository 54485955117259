import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Settings from './pages/Settings';
import Blog from './pages/Blog';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Forgot from "./pages/Forgot";
import Reset from "./pages/Reset";
import EmailConfirmationCheck from "./pages/EmailConfirmationCheck";
import EmailConfirmation from "./pages/EmailConfirmation";
import Home from "./pages/website/Home";
import About from "./pages/website/About";
import Contact from "./pages/website/Contact";
import Inventory from "./pages/Inventory";
import Sales from "./pages/Sales";
import Purchases from "./pages/Purchases";
import CalBookings from "./pages/cal/Bookings";
import Vehicle from "./pages/Vehicle";
import Register from "./pages/Register";
import CalUserBookings from "./pages/cal/UserBooking";
import BookingsList from "./pages/BookingsList";
import CalUserBookingsVehicle from "./pages/cal/UserBookingsVehicle";
import Users from "./pages/Users";
import VehicleAdmin from "./pages/AdminVehicle";
import VehicleUserAdmin from "./pages/AdminUserVehicle";

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '/dashboard',
            element: <DashboardLayout/>,
            children: [
                {path: 'app', element: <DashboardApp/>},
                {path: 'settings', element: <Settings component={'settings'} title={'Settings'}/>},

                // { path: 'booking', element: <Booking component={'booking'} api={'bookings'} mode={'view'} /> },
                // { path: 'booking/new', element: <Booking component={'booking'} api={'bookings'} mode={'create'} /> },
                // { path: 'booking/edit/:id', element: <Booking component={'booking'} api={'bookings'} mode={'edit'} /> },

                // { path: 'vehicle', element: <Vehicle component={'vehicle'} api={'vehicles'} mode={'view'} /> },
                // { path: 'vehicle/new', element: <Vehicle component={'vehicle'} api={'vehicles'} mode={'create'} /> },
                // { path: 'vehicle/edit/:id', element: <Vehicle component={'vehicle'} api={'vehicles'} mode={'edit'} /> },

                // { path: 'offtime', element: <OffTime component={'offtime'} api={'offtimes'} mode={'view'} /> },
                // { path: 'offtime/new', element: <OffTime component={'offtime'} api={'offtimes'} mode={'create'} /> },
                // { path: 'offtime/edit/:id', element: <OffTime component={'offtime'} api={'offtimes'} mode={'edit'} /> },


                {path: 'inventory', element: <Inventory component={'inventory'} api={'inventories'} mode={'view'}/>},
                {
                    path: 'inventory/new',
                    element: <Inventory component={'inventory'} api={'inventories'} mode={'create'}/>
                },
                {
                    path: 'inventory/edit/:id',
                    element: <Inventory component={'inventory'} api={'inventories'} mode={'edit'}/>
                },

                {path: 'sale', element: <Sales component={'sale'} api={'sales'} mode={'view'}/>},
                {path: 'sale/new', element: <Sales component={'sale'} api={'sales'} mode={'create'}/>},
                {path: 'sale/edit/:id', element: <Sales component={'sale'} api={'sales'} mode={'edit'}/>},

                {path: 'purchase', element: <Purchases component={'purchase'} api={'purchases'} mode={'view'}/>},
                {path: 'purchase/new', element: <Purchases component={'purchase'} api={'purchases'} mode={'create'}/>},
                {
                    path: 'purchase/edit/:id',
                    element: <Purchases component={'purchase'} api={'purchases'} mode={'edit'}/>
                },

                {path: 'user', element: <Users component={'user'} api={'users'} mode={'view'}/>},
                // {path: 'user/new', element: <Users component={'user'} api={'users'} mode={'create'}/>},
                // {path: 'user/edit/:id', element: <Users component={'user'} api={'users'} mode={'edit'}/>},

                {path: 'vehicle-admin', element: <VehicleAdmin component={'vehicle'} api={'vehicles'} mode={'view'}/>},
                // {path: 'vehicle-admin/new', element: <VehicleAdmin component={'vehicle'} api={'vehicles'} mode={'create'}/>},
                {path: 'vehicle-admin/edit/:id', element: <VehicleAdmin component={'vehicle'} api={'vehicles'} mode={'edit'}/>},

                {path: 'vehicle-admin/:id', element: <VehicleUserAdmin component={'vehicle'} api={'vehicles'} mode={'view'}/>},

                {path: 'products', element: <Products/>},
                {path: 'blog', element: <Blog/>},
            ],
        },

        { path: 'dashboard/vehicle', element: <Vehicle component={'vehicle'} api={'vehicles'} mode={'view'} /> },
        { path: 'dashboard/vehicle/new', element: <Vehicle component={'vehicle'} api={'vehicles'} mode={'create'} /> },
        { path: 'dashboard/vehicle/edit/:id', element: <Vehicle component={'vehicle'} api={'vehicles'} mode={'edit'} /> },

        { path: 'dashboard/bookings', element: <BookingsList component={'bookings'} api={'bookings'} mode={'view'} /> },

        {
            path: 'login',
            element: <Login/>,
        },
        {
          path: 'guest/bookings',
          element: <CalBookings/>
        },
        {
            path: 'user/bookings-vehicle',
            element: <CalUserBookingsVehicle/>
        },
        {
            path: 'user/bookings',
            element: <CalUserBookings/>
        },
        {
          path: 'register',
          element: <Register />,
        },
        {
            path: 'forgot',
            element: <Forgot/>,
        },
        {
            path: 'reset',
            element: <Reset/>,
        },
        {
            path: 'verify',
            element: <EmailConfirmationCheck/>,
        },
        {
            path: 'confirmation',
            element: <EmailConfirmation/>,
        },
        {
            path: '/',
            element: <LogoOnlyLayout/>,
            children: [
                {path: '/', element: <Home title={"Home"}/>},
                {path: '/about', element: <About title={"About"}/>},
                {path: '/contact', element: <Contact title={"Contact"}/>},
                {path: '404', element: <NotFound/>},
                {path: '*', element: <Navigate to="/404"/>},
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace/>,
        },
    ]);
}
