// components
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
// form
// @mui
import {IconButton, InputAdornment, Stack, TextField, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Iconify from '../../../components/Iconify';
import AuthActions from "../../../actions/AuthActions";

// ----------------------------------------------------------------------

export default function ResetForm() {

    const navigate = useNavigate();
    const {search} = useLocation();
    const verifyCode = new URLSearchParams(search).get('code');
    const [loading, setLoading] = useState(false);

    const schema = {
        password: "",
        passwordConfirmation: "",
        code: verifyCode
    }
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [codeStatus, setCodeStatus] = useState(true);
    const [data, setData] = useState(schema);
    const [errors, setErrors] = useState(schema);
    const onChange = e => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };


    const errorFunc = (err) => {
        console.log(err);

        if (err.message === "Request failed with status code 429") {
            console.log("Rate limit exceeded");
        }

        const errorResponseMsg = err.response.data.error.message;
        const errorResponse = err.response.data.error.details.errors;

        if (errorResponseMsg === "Passwords do not match") {
            console.log(errorResponseMsg)
            setErrors({passwordConfirmation: "Passwords do not match"});
        } else if (errorResponseMsg === "Incorrect code provided") {
            console.log(errorResponseMsg)
            setCodeStatus(false);
        } else {
            let tempErrors = {};
            const updateError = (item, i) => {
                const data0 = {[item.path[0]]: item.message};
                tempErrors = Object.assign(tempErrors, data0);

                if (i === errorResponse.length - 1) {
                    setErrors(tempErrors);
                }
            }

            if (errorResponse) {
                errorResponse.forEach(updateError)
            }
        }
    }

    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setErrors(schema);

        AuthActions.ResetPassword(data).then((res) => {
            setLoading(false);
            console.log(res);
        }).catch(err => {
            setLoading(false);

            errorFunc(err);

        })
    };

    useEffect(() => {
        if (!verifyCode) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            {codeStatus === false ?
                <Typography variant="h6" gutterBottom
                            style={{
                                color: "red",
                                border: "1px dashed red",
                                padding: "20px",
                                borderRadius: "12px"
                            }}
                >
                    <div className={"con-mid"}>
                        <Iconify icon="eva:alert-triangle-outline"/>
                        Expired or invalid reset link
                    </div>
                </Typography>
                :
                <form onSubmit={onSubmit}>
                    <Stack spacing={3}>
                        <TextField
                            name="password"
                            id={"password"}
                            label="New password"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                style: {fontSize: 13},
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={onChange}
                            value={data.password}
                            error={!!errors.password}
                            helperText={errors.password}
                            InputLabelProps={{style: {fontSize: 13}}}
                        />
                        <TextField
                            name="passwordConfirmation"
                            id={"passwordConfirmation"}
                            label="Re-type new password"
                            type={showPassword2 ? 'text' : 'password'}
                            InputProps={{
                                style: {fontSize: 13},
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                                            <Iconify icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={onChange}
                            value={data.passwordConfirmation}
                            error={!!errors.passwordConfirmation}
                            helperText={errors.passwordConfirmation}
                            InputLabelProps={{style: {fontSize: 13}}}
                        />
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}/>

                    <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained" className={"auth-btn"}>
                        Continue
                    </LoadingButton>
                </form>
            }
        </>
    );
}
