// @mui
import {Container} from '@mui/material';
// components
import Page from '../../components/Page';
import * as React from "react";
import userData from "../../config/CheckAuth";
import {InlineWidget} from "react-calendly";


export default function CalBookings() {

    let calLink = "vic-service-centre-n0pv4a";

    function onSwitchBookings() {
        window.location.href = "/login";
    }

    const user = userData();

    if (user) {
        document.location.href = '/dashboard/vehicle';
    } else {

        return (
            <Page title="Bookings" style={{backgroundColor: "#101010", minHeight: "100vh"}}>
                <Container className={"cal"}>
                    <div style={{padding: "50px 0"}}>
                        <div>
                            <h3 style={{color: "#fff", margin: "30px 20px 10px", textAlign: "center"}}>
                                Guest Booking
                            </h3>

                            <div style={{
                                width: "100%",
                                fontSize: "12px",
                                textAlign: "center",
                                marginBottom: "30px",
                                color: "#b0b0b0"
                            }}>
                                <span>Switch to </span>
                                <button
                                    onClick={onSwitchBookings}
                                    className={"switch-btn"}
                                >User Bookings
                                </button>
                            </div>

                        </div>
                        {/* <Cal
                            calLink={calLink}
                            config={{
                                // name: "John Doe",
                                // email: "johndoe@gmail.com",
                                // notes: "Test Meeting",
                                // guests: ["janedoe@gmail.com"],
                                theme: "dark",
                            }}
                        /> */}


                        <InlineWidget
                            styles={{
                                height: '700px'
                            }}
                            url="https://calendly.com/vic-service?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=1c1c1c&text_color=ffffff&primary_color=2bc8b0"
                        />

                    </div>

                </Container>
            </Page>
        );
    }
}
