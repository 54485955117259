import {Link as RouterLink} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {Container, Link, Typography} from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import userData from "../config/CheckAuth";
import ResetForm from "../sections/auth/reset/ResetForm";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({theme}) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

// const SectionStyle = styled(Card)(({theme}) => ({
//     width: '100%',
//     maxWidth: 464,
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     margin: theme.spacing(2, 0, 2, 2),
// }));

const ContentStyle = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Forgot() {
    const smUp = useResponsive('up', 'sm');

    // const mdUp = useResponsive('up', 'md');

    const user = userData();

    if (user) {
        document.location.href = '/dashboard/inventory';
    } else {

        return (
            <div className={"auth-page"}>
                <Page title="Login" className={"auth-container"} style={{backgroundImage: "url(/img/auth-bg.png)"}}>
                    <RootStyle>
                        <HeaderStyle>
                            <Logo/>

                            {smUp && (
                                <Typography variant="body2" sx={{mt: {md: -2}}}>
                                    Back to {' '}
                                    <Link variant="subtitle2" component={RouterLink} to="/login">
                                        Sign in
                                    </Link>
                                </Typography>
                            )}
                        </HeaderStyle>


                        <Container maxWidth="sm">
                            <ContentStyle>
                                <div className={"login-card"}>
                                    <Typography variant="h5" gutterBottom>
                                        Reset password
                                    </Typography>

                                    <Typography sx={{color: 'text.secondary', mb: 5, fontSize: "14px"}}>
                                        Reset your forgotten password
                                    </Typography>


                                    <ResetForm/>

                                </div>
                            </ContentStyle>

                        </Container>
                    </RootStyle>
                </Page>
            </div>
        );
    }
}
