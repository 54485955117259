import axios from 'axios';
import Keys from "../config/Keys";

const {API_URL} = Keys;

class AdminActions {
    // eslint-disable-next-line class-methods-use-this
    CreateUser(data) {
        return axios.post(`${API_URL}/api/auth/local/register`, data, {});
    }

    // eslint-disable-next-line class-methods-use-this
    LoginUser(data) {
        return axios.post(`${API_URL}/api/auth/local`, data, {});
    }

    // eslint-disable-next-line class-methods-use-this
    ForgotPassword(identifier) {
        return axios.post(`${API_URL}/api/auth/forgot-password`, {email: identifier}, {});
    }

    // eslint-disable-next-line class-methods-use-this
    ResetPassword(data) {
        return axios.post(`${API_URL}/api/auth/reset-password`, data, {});
    }

    // eslint-disable-next-line class-methods-use-this
    EmailConfirmation(confirmation) {
        return axios.get(`${API_URL}/api/auth/email-confirmation?confirmation=${confirmation}`, {});
    }

    // eslint-disable-next-line class-methods-use-this
    ResendEmailConfirmation(email) {
        return axios.post(`${API_URL}/api/auth/send-email-confirmation`, {email}, {})
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Google Auth
    // -----------------------------------------------------------------------------------------------------------------
    // eslint-disable-next-line class-methods-use-this
    GoogleAuthCallback(accessToken) {
        return axios.get(`${API_URL}/api/auth/google/callback?access_token=${accessToken}`, {})
    }

    // eslint-disable-next-line class-methods-use-this
    fetchHistoryBookings(email) {
        return axios.get(
            `https://api.calendly.com/scheduled_events?user=https://api.calendly.com/users/e1375d70-b66e-463b-821b-81d634f4d639&invitee_email=${email}`,
            {headers:
                    {Authorization: `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjcwNzQxMjUxLCJqdGkiOiI2ZWI2N2QzNC1jYTYwLTRiMDgtYmY4OS0yMTFjZTIxMzkwMmUiLCJ1c2VyX3V1aWQiOiJlMTM3NWQ3MC1iNjZlLTQ2M2ItODIxYi04MWQ2MzRmNGQ2MzkifQ.PZn9FHnB2AAlbQ4AFLZ0z_cM8kECJeDg8SSFIZIOfDMIo1VPXkf2PbVU_v1wohHpYyoS1BFwf30u0uyI1EQGUw`}});
    }

}

export default new AdminActions();