// @mui
import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    LinearProgress,
    TextField,
    Typography
} from '@mui/material';
// components
import * as React from "react";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Page from '../components/Page';
import SettingsActions from "../actions/SettingsActions";

// ----------------------------------------------------------------------

Settings.propTypes = {
    title: PropTypes.node.isRequired
};
export default function Settings({title}) {

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const schema = {
        id: null,
        first_name: "",
        last_name: "",
        mobile_number: "",
        address: ""
    }

    const passwordSchema = {
        currentPassword: "",
        password: "",
        passwordConfirmation: ""
    }

    const [data, setData] = useState(schema);
    const [errors, setErrors] = useState(schema);

    const [pwData, setPwData] = useState(passwordSchema);
    const [pwErrors, setPwErrors] = useState(passwordSchema);

    const onChange = e => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    }

    const onPwChange = e => {
        const {name, value} = e.target;
        setPwData({...pwData, [name]: value});
    }

    const onSubmit = () => {
        setLoading(true);
        SettingsActions.updateUser(data.id, data).then(res => {
            setLoading(false);
            console.log(res);
        }).catch(err => {
            setLoading(false);
            const errorResponse = err.response.data.error.details.errors;
            let tempErrors = {};
            const updateError = (item, i) => {
                const data0 = {[item.path[0]]: item.message};
                tempErrors = Object.assign(tempErrors, data0);

                if (i === errorResponse.length - 1) {
                    setErrors(tempErrors);
                }
            }
            errorResponse.forEach(updateError)
        })
    }

    const onPwSubmit = () => {
        setLoading2(true);
        SettingsActions.changePassword(data.id, pwData).then(res => {
            setLoading2(false);
            console.log(res);
        }).catch(err => {
            setLoading2(false);
            const errorResponseMsg = err.response.data.error.message;
            const errorResponse = err.response.data.error.details.errors;

            if (errorResponseMsg === "The provided current password is invalid") {

                setPwErrors({currentPassword: errorResponseMsg});

            } else {

                let tempErrors = {};
                const updateError = (item, i) => {
                    const data0 = {[item.path[0]]: item.message};
                    tempErrors = Object.assign(tempErrors, data0);

                    if (i === errorResponse.length - 1) {
                        setPwErrors(tempErrors);
                    }
                }
                errorResponse.forEach(updateError)

            }

        })
    }

    useEffect(() => {
        setLoading(true);

        SettingsActions.me().then(res => {
            setLoading(false);
            setData({...data, "id": res.data.id, "first_name": res.data.first_name, "last_name": res.data.last_name, "mobile_number": res.data.mobile_number, "address": res.data.address});
        }).catch(err => {
            console.log(err);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page title="Dashboard">
            {
                loading === true ?
                    <div className={"loading-bar"}>
                        <LinearProgress/>
                    </div> : ""
            }
            <Container maxWidth="xl">
                <Typography variant="h4" gutterBottom>
                    {title}
                </Typography>
                <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <div className={"form-helper-div"}>

                        <fieldset>
                            <legend style={{textTransform: "capitalize"}}>Personal Details</legend>

                            <TextField
                                label={"First name"}
                                type={"text"}
                                name={"first_name"}
                                // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                sx={{m: 1, width: 'calc(50% - 16px)'}}
                                variant={"outlined"}
                                inputProps={{style: {fontSize: 13}}}
                                InputLabelProps={{style: {fontSize: 13}}}
                                value={data.first_name}
                                onChange={onChange}
                                error={!!errors.first_name}
                                helperText={errors.first_name}
                            />

                            <TextField
                                label={"Last name"}
                                type={"text"}
                                name={"last_name"}
                                // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                sx={{m: 1, width: 'calc(50% - 16px)'}}
                                variant={"outlined"}
                                inputProps={{style: {fontSize: 13}}}
                                InputLabelProps={{style: {fontSize: 13}}}
                                value={data.last_name}
                                onChange={onChange}
                                error={!!errors.last_name}
                                helperText={errors.last_name}
                            />

                            <TextField
                                label={"Mobile Number"}
                                type={"text"}
                                name={"mobile_number"}
                                // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                sx={{m: 1, width: 'calc(50% - 16px)'}}
                                variant={"outlined"}
                                inputProps={{style: {fontSize: 13}}}
                                InputLabelProps={{style: {fontSize: 13}}}
                                value={data.mobile_number}
                                onChange={onChange}
                                error={!!errors.mobile_number}
                                helperText={errors.mobile_number}
                            />

                            <TextField
                                label={"Address"}
                                type={"text"}
                                name={"address"}
                                // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                sx={{m: 1, width: 'calc(50% - 16px)'}}
                                variant={"outlined"}
                                inputProps={{style: {fontSize: 13}}}
                                InputLabelProps={{style: {fontSize: 13}}}
                                value={data.address}
                                onChange={onChange}
                                error={!!errors.address}
                                helperText={errors.address}
                            />

                            <div style={{width: "100%", marginTop: "20px"}} className={"con-left"}>
                                <FormControl sx={{m: 1, width: '180px'}}>
                                    <Button onClick={onSubmit} type="submit"
                                            variant="contained">
                                        {loading === true ?
                                            <CircularProgress size={"1.2rem"} style={{color: "#fff"}}/>
                                            : "Update"}
                                    </Button>
                                </FormControl>
                            </div>

                        </fieldset>

                        <fieldset className={"change-password"}>
                            <legend style={{textTransform: "capitalize"}}>Change Password</legend>

                            <TextField
                                label={"Current Password"}
                                type={"text"}
                                name={"currentPassword"}
                                // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                sx={{m: 1, width: 'calc(50% - 16px)'}}
                                variant={"outlined"}
                                inputProps={{style: {fontSize: 13}}}
                                InputLabelProps={{style: {fontSize: 13}}}
                                value={data.currentPassword}
                                onChange={onPwChange}
                                error={!!pwErrors.currentPassword}
                                helperText={pwErrors.currentPassword}
                            />

                            <FormControl sx={{m: 1, width: 'calc(50% - 16px)'}}/>

                            <TextField
                                label={"New Password"}
                                type={"text"}
                                name={"password"}
                                // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                sx={{m: 1, width: 'calc(50% - 16px)'}}
                                variant={"outlined"}
                                inputProps={{style: {fontSize: 13}}}
                                InputLabelProps={{style: {fontSize: 13}}}
                                value={data.password}
                                onChange={onPwChange}
                                error={!!pwErrors.password}
                                helperText={pwErrors.password}
                            />

                            <TextField
                                label={"Re-type new Password"}
                                type={"text"}
                                name={"passwordConfirmation"}
                                // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                sx={{m: 1, width: 'calc(50% - 16px)'}}
                                variant={"outlined"}
                                inputProps={{style: {fontSize: 13}}}
                                InputLabelProps={{style: {fontSize: 13}}}
                                value={data.passwordConfirmation}
                                onChange={onPwChange}
                                error={!!pwErrors.passwordConfirmation}
                                helperText={pwErrors.passwordConfirmation}
                            />

                            <div style={{width: "100%", marginTop: "20px"}} className={"con-left"}>
                                <FormControl sx={{m: 1, width: '180px'}}>
                                    <Button onClick={onPwSubmit} type="submit"
                                            variant="contained">
                                        {loading2 === true ?
                                            <CircularProgress size={"1.2rem"} style={{color: "#fff"}}/>
                                            : "Update"}
                                    </Button>
                                </FormControl>
                            </div>

                        </fieldset>
                    </div>
                </Box>
            </Container>
        </Page>
    );
}
