import * as React from 'react';
import PropTypes from 'prop-types';

About.propTypes = {
    title: PropTypes.node.isRequired,
};

export default function About({title}) {
    return (
        <iframe src={"/website/about.html"} className="full-iframe" />
    );
}
