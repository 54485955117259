import {useEffect, useRef, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// @mui
import {alpha} from '@mui/material/styles';
import {Avatar, Box, Divider, IconButton, MenuItem, Stack, Typography} from '@mui/material';
// components
import {GoogleLogout} from "react-google-login";
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import SettingsActions from "../../actions/SettingsActions";
import Keys from "../../config/Keys";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    // {
    //     label: 'Home',
    //     icon: 'eva:home-fill',
    //     linkTo: '/',
    // },
    // {
    //     label: 'Profile',
    //     icon: 'eva:person-fill',
    //     linkTo: '#',
    // },
    {
        label: 'Settings',
        icon: 'eva:settings-2-fill',
        linkTo: '/dashboard/settings',
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const anchorRef = useRef(null);

    const [open, setOpen] = useState(null);
    const [user, setUser] = useState({});

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem("jwt");
        navigate('/login', {replace: true});
    }

    const logoutUser = () => {
        localStorage.removeItem("jwt");
        navigate('/login', {replace: true});
    }

    useEffect(() => {
        SettingsActions.me().then(res => {
            setUser(res.data);
        }).catch(err => {
            console.log(err);
        })
    }, [])

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar src={user.image ? user.image : account.photoURL} alt="photoURL"/>
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle2" noWrap>
                        {user.first_name}
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                        {user.email}
                    </Typography>
                </Box>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <Stack sx={{p: 1}}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{borderStyle: 'dashed'}}/>

                {user.provider === "google" ?
                    <MenuItem>
                        <GoogleLogout
                            clientId={Keys.GOOGLE_CLIENT_ID}
                            buttonText="Logout"
                            onLogoutSuccess={logout}
                            onLogoutFailure={logoutUser}
                            className={"google-logout-btn"}
                        />
                    </MenuItem>
                    :
                    <MenuItem onClick={logoutUser} sx={{m: 1}}>
                        Logout
                    </MenuItem>
                }
            </MenuPopover>
        </>
    );
}
