import * as React from 'react';
import {useEffect, useState} from 'react';
import {filter} from 'lodash';
import PropTypes from 'prop-types';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
// material
import {
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
// components
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead, UserListToolbar, UserMoreMenu} from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';
import UserActions from "../actions/UserActions";
import SettingsActions from "../actions/SettingsActions";


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: 'Email', label: 'Email', alignRight: false},
    {id: 'Name', label: 'Name', alignRight: false},
    // {id: 'Login', label: 'Login', alignRight: false},
    {id: 'Confirmed', label: 'Confirmed', alignRight: false},
    {id: 'Blocked', label: 'Blocked', alignRight: false},
    {id: 'Vehicles', label: 'Vehicles', alignRight: false},
    {id: 'Date', label: 'Joined Date', alignRight: false},
    {id: ''},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

Users.propTypes = {
    component: PropTypes.node.isRequired,
    api: PropTypes.node.isRequired,
    mode: PropTypes.node.isRequired
};

export default function Users({component, api, mode}) {

    const [loading, setLoading] = useState(false);

    // const role = "admin";
    // const [role, setRole] = useState();
    const navigate = useNavigate();

    const schema = {
        Product: "",
        Quantity: ""
    }
    const [data, setData] = useState(schema);
    const [errors, setErrors] = useState(schema);
    const onChange = e => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };
    const changeDate = (newDate, eleId) => {
        setData({...data, [eleId]: new Date(newDate.$d)});
        console.log(data);
    }
    // const changeDateEnd = (newDate) => {
    //     setData({...data, "End": newDate});
    // }
    // const handleDateChange = (e) => {
    //     const {name, value} = e.target;
    //     console.log(name, value);
    //     setData({...data, [name]: value});
    // };
    const handleTimeChange = (e) => {
        const {name, value} = e.target;
        const formattedValue = `${value}:00.000`;
        console.log(name, formattedValue);
        setData({...data, [name]: formattedValue});
    };

    const [vehicles, setVehicles] = useState([]);

    const location = useLocation();

    const [USERLIST, SETUSERLIST] = useState([]);

    const [slug, setSlug] = useState();

    const [editId, setEditId] = useState();

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [title, setTitle] = useState("");

    const [attributes, setAttributes] = useState([]);


    // const label = {inputProps: {'aria-label': 'Color switch demo'}};

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    const errorFunc = (err) => {
        console.log(err);

        if (err.message === "Request failed with status code 429") {
            console.log("Rate limit exceeded");
        }

        const errorResponseMsg = err.response.data.error.message;
        const errorResponse = err.response.data.error.details.errors;

        let tempErrors = {};
        const updateError = (item, i) => {
            const data0 = {[item.path[0]]: item.message};
            tempErrors = Object.assign(tempErrors, data0);

            if (i === errorResponse.length - 1) {
                setErrors(tempErrors);
            }
        }

        if (!errorResponse) {
            if (errorResponseMsg) {
                if (errorResponseMsg === "Invalid date") {

                    console.log(errorResponseMsg);

                    let tempErrors = {};
                    for (let i = 0; i < attributes.length; i += 1) {
                        if (attributes[i][1].type === "date") {
                            const data0 = {[attributes[i][0]]: errorResponseMsg};
                            tempErrors = Object.assign(tempErrors, data0);
                        }

                        if (i === attributes.length - 1) {
                            setErrors(tempErrors);
                        }
                    }

                } else if (errorResponseMsg === "Invalid time format, expected HH:mm:ss.SSS") {

                    console.log(errorResponseMsg);

                    let tempErrors = {};
                    for (let i = 0; i < attributes.length; i += 1) {
                        if (attributes[i][1].type === "time") {
                            const data0 = {[attributes[i][0]]: errorResponseMsg};
                            tempErrors = Object.assign(tempErrors, data0);
                        }

                        if (i === attributes.length - 1) {
                            setErrors(tempErrors);
                        }
                    }

                } else {
                    console.log(errorResponseMsg);
                }
            }
        } else {
            console.log(errorResponse);
            errorResponse.forEach(updateError)
        }
    }

    const onCreateSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setErrors(schema);

        if (mode === "create") {
            await UserActions.Create({data}, api).then((r) => {
                setLoading(false);
                console.log(r);
                navigate('/dashboard/purchase', {replace: true});
            }).catch(err => {
                setLoading(false);
                errorFunc(err);
            })
        } else if (mode === "edit") {
            await UserActions.updateData(editId, {data}, api).then((r) => {
                setLoading(false);
                console.log(r);
                navigate('/dashboard/purchase', {replace: true});
            }).catch(err => {
                setLoading(false);
                errorFunc(err);
            })
        }

    };

    useEffect(() => {

        SettingsActions.me().then(() => {
            // setRole(userRes.data.roleName);
        }).catch(userErr => {
            console.log(userErr);
        })


        const pageDataLoader = (slugParam) => {
            /* UserActions.GetComponent(slugParam).then((res) => {

                 const resAttr = res.data.data.schema.attributes;
                 // setAttributes(Object.entries(resAttr));
                 // setTitle(res.data.data.schema.displayName);

             }).catch(err => {
                 console.log(err);
             })*/

            setAttributes("");
            setTitle("Users");
        }


        const editItemLoader = (editIdParam) => {
            UserActions.GetEditData(editIdParam, api).then((res) => {
                setData(res.data.data.attributes);
            }).catch(err => {
                navigate('/login', {replace: true});
                console.log(err);
            })
        }

        const dataFetch = () => {
            setLoading(true);
            UserActions.GetData('users').then((res) => {

                const fetchedData = res.data;

                const tempDataArray = [];
                setLoading(false);

                for (let i = 0; i < fetchedData.length; i += 1) {
                    Object.assign(fetchedData[i], {"id": fetchedData[i].id})

                    if (fetchedData[i].roleName !== 'admin') {
                        tempDataArray.push(fetchedData[i]);
                    }

                    if (i === fetchedData.length - 1) {
                        SETUSERLIST(tempDataArray);
                    }
                }

            }).catch(err => {
                // navigate('/login', {replace: true});
                console.log(err);
            })

            UserActions.GetProducts().then((res) => {
                const fetchedData = res.data.data;
                setVehicles(fetchedData);
            }).catch(err => {
                console.log(err);
            })
        }

        if (mode === "edit") {

            const slugParam = component;
            const editIdParam = location.pathname.split('/').pop();

            // setMode(operation);
            setSlug(slugParam);
            setEditId(editIdParam);

            pageDataLoader(slugParam);
            editItemLoader(editIdParam);

        } else if (mode === "view") {
            setData(schema);
            const slugParam = component;
            setSlug(slugParam)
            pageDataLoader(slugParam);
            dataFetch();
        } else if (mode === "create") {
            setData(schema);
            const slugParam = component;
            setSlug(slugParam)
            pageDataLoader(slugParam);
            dataFetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, navigate])


    return (
        <Page title={title}>
            {
                loading === true ?
                    <div className={"loading-bar"}>
                        <LinearProgress/>
                    </div> : ""
            }
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {title}
                    </Typography>
                    {/* <Button variant="contained" component={RouterLink} to={`/dashboard/${slug}/new`}
                            startIcon={<Iconify icon="eva:plus-fill"/>}>
                        New {slug}
                    </Button> */}
                </Stack>

                <Card className={"dash-card"}>

                    {mode === "create" || mode === "edit" ?
                        <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                            <div className={"form-helper-div"}>

                                <fieldset>
                                    <legend
                                        style={{textTransform: "capitalize"}}>{mode === "edit" ? `Update ${slug}` : `New ${slug}`}</legend>

                                    {attributes.map((attribute, index) => (
                                        // eslint-disable-next-line no-nested-ternary
                                        attribute[0] === "user" ? "" :
                                            // eslint-disable-next-line no-nested-ternary
                                            attribute[1].type === "string" ?
                                                <TextField
                                                    label={attribute[0]}
                                                    type={"text"}
                                                    name={attribute[0]}
                                                    // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                    sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                    variant={"outlined"}
                                                    key={index}
                                                    inputProps={{style: {fontSize: 13}}}
                                                    InputLabelProps={{style: {fontSize: 13}}}
                                                    value={data[attribute[0]]}
                                                    onChange={onChange}
                                                    error={!!errors[attribute[0]]}
                                                    helperText={errors[attribute[0]]}
                                                />
                                                :
                                                // eslint-disable-next-line no-nested-ternary
                                                attribute[1].type === "enumeration" ?
                                                    <FormControl variant="outlined"
                                                                 key={index}
                                                        // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                                 sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                    >
                                                        <InputLabel id={`demo-simple-select-standard-label-${index}`}
                                                                    style={errors[attribute[0]] ? {
                                                                        fontSize: 13,
                                                                        color: "#FF4842"
                                                                    } : {fontSize: 13}}
                                                        >{attribute[0]}</InputLabel>
                                                        <Select
                                                            labelId={`demo-simple-select-standard-label-${index}`}
                                                            name={attribute[0]}
                                                            label={attribute[0]}
                                                            style={{fontSize: 13}}
                                                            value={data[attribute[0]]}
                                                            onChange={onChange}
                                                            error={!!errors[attribute[0]]}
                                                            // helpertext={errors[attribute[0]]}
                                                        >
                                                            <MenuItem style={{display: 'none'}}>
                                                                <span style={{color: "transparent"}}>_</span>
                                                            </MenuItem>
                                                            {attribute[1].enum.map((item, itemIndex) => (
                                                                <MenuItem key={itemIndex} value={item}>{item}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors[attribute[0]] ? <span
                                                            className={"error-helper"}>{errors[attribute[0]]}</span> : ""}
                                                    </FormControl>
                                                    :
                                                    // eslint-disable-next-line no-nested-ternary
                                                    attribute[1].type === "datetime" ?

                                                        attribute[0] === "Date" ? "" :
                                                            <FormControl variant="outlined"
                                                                         key={index}
                                                                // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                                         sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                            >

                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <Stack spacing={3}>


                                                                        <DateTimePicker
                                                                            renderInput={(props) =>
                                                                                <TextField {...props} />}
                                                                            name={attribute[0]}
                                                                            id={attribute[0]}
                                                                            label={attribute[0]}

                                                                            minDate={`${new Date(new Date()).getFullYear()}-${parseInt(new Date(new Date()).getMonth(), 10) + 1}-${new Date(new Date()).getDate()}`}
                                                                            maxDate={`${new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000)).getFullYear()}-${parseInt(new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000)).getMonth(), 10) + 1}-${new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000)).getDate()}`}

                                                                            type="date"
                                                                            // defaultValue=""
                                                                            value={data[attribute[0]]}
                                                                            onChange={(newValue) => {
                                                                                changeDate(newValue, attribute[0]);
                                                                            }}
                                                                            inputProps={{style: {fontSize: 13}}}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                style: {fontSize: 13}
                                                                            }}
                                                                            error={!!errors[attribute[0]]}
                                                                            helperText={errors[attribute[0]]}
                                                                        />


                                                                    </Stack>
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        // eslint-disable-next-line no-nested-ternary
                                                        : attribute[1].type === "time" ?
                                                            <FormControl variant="outlined"
                                                                         key={index}
                                                                // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                                         sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                            >

                                                                <TextField
                                                                    className={"time-field"}
                                                                    name={attribute[0]}
                                                                    id={attribute[0]}
                                                                    label={attribute[0]}
                                                                    type="time"
                                                                    // defaultValue=""
                                                                    value={data[attribute[0]]}
                                                                    onChange={(e) => handleTimeChange(e)}
                                                                    inputProps={{style: {fontSize: 13}}}
                                                                    InputLabelProps={{shrink: true, style: {fontSize: 13}}}
                                                                    error={!!errors[attribute[0]]}
                                                                    helperText={errors[attribute[0]]}
                                                                />

                                                            </FormControl>
                                                            // eslint-disable-next-line no-nested-ternary
                                                            : attribute[1].type === "integer" ?

                                                                // eslint-disable-next-line no-nested-ternary
                                                                attribute[0] === "Product" ?
                                                                    <FormControl variant="outlined"
                                                                                 key={index}
                                                                        // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                                                 sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                                    >
                                                                        <InputLabel
                                                                            id={`demo-simple-select-standard-label-${index}`}
                                                                            style={errors[attribute[0]] ? {
                                                                                fontSize: 13,
                                                                                color: "#FF4842"
                                                                            } : {fontSize: 13}}
                                                                        >{attribute[0]}</InputLabel>
                                                                        <Select
                                                                            labelId={`demo-simple-select-standard-label-${index}`}
                                                                            name={attribute[0]}
                                                                            label={attribute[0]}
                                                                            style={{fontSize: 13}}
                                                                            value={data[attribute[0]]}
                                                                            onChange={onChange}
                                                                            error={!!errors[attribute[0]]}
                                                                            // helpertext={errors[attribute[0]]}
                                                                        >
                                                                            <MenuItem style={{display: 'none'}}>
                                                                                <span
                                                                                    style={{color: "transparent"}}>_</span>
                                                                            </MenuItem>
                                                                            {vehicles.map((item, itemIndex) => (
                                                                                item.attributes.Quantity !== 0 ?
                                                                                    <MenuItem key={itemIndex}
                                                                                              value={item.id}>{`${item.attributes.Product}`}</MenuItem> : ""
                                                                            ))}
                                                                        </Select>
                                                                        {errors[attribute[0]] ? <span
                                                                            className={"error-helper"}>{errors[attribute[0]]}</span> : ""}
                                                                    </FormControl>
                                                                    :

                                                                    attribute[0] === "Order" ? "" :
                                                                        <TextField
                                                                            label={attribute[0]}
                                                                            type="number"
                                                                            name={attribute[0]}
                                                                            // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                                            sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                                            variant={"outlined"}
                                                                            key={index}
                                                                            inputProps={{
                                                                                style: {fontSize: 13},
                                                                                inputMode: 'numeric',
                                                                                pattern: '[0-9]*'
                                                                            }}
                                                                            InputLabelProps={{style: {fontSize: 13}}}
                                                                            value={data[attribute[0]]}
                                                                            onChange={onChange}
                                                                            error={!!errors[attribute[0]]}
                                                                            helperText={errors[attribute[0]]}
                                                                        />
                                                                // eslint-disable-next-line no-nested-ternary
                                                                : attribute[1].type === "password" ?
                                                                    <TextField
                                                                        name={attribute[0]}
                                                                        label={attribute[0]}
                                                                        type="password"
                                                                        key={index}
                                                                        sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                                        autoComplete="current-password"
                                                                        inputProps={{style: {fontSize: 13}}}
                                                                        InputLabelProps={{style: {fontSize: 13}}}
                                                                        value={data[attribute[0]]}
                                                                        onChange={onChange}
                                                                        error={!!errors[attribute[0]]}
                                                                        helperText={errors[attribute[0]]}
                                                                    />
                                                                    // eslint-disable-next-line no-nested-ternary
                                                                    : attribute[1].type === "boolean" ? ""
                                                                        : attribute[1].type === "media" ? "" : ""

                                    ))}


                                    <div style={{width: "100%", marginTop: "20px"}} className={"con-left"}>
                                        <FormControl sx={{m: 1, width: '180px'}}>
                                            <Button onClick={onCreateSubmit} type="submit"
                                                    variant="contained">
                                                {
                                                    // eslint-disable-next-line no-nested-ternary
                                                    loading === true ? <CircularProgress size={"1.2rem"}
                                                                                         style={{color: "#fff"}}/> : mode === "edit" ? "Update" : "Add"
                                                }
                                            </Button>
                                        </FormControl>
                                    </div>

                                    {/* <FormControl sx={{m: 1, width: 'calc(50% - 16px)'}} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    // value={values.weight}
                                    // onChange={handleChange('weight')}
                                    endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText>
                            </FormControl> */}

                                </fieldset>
                            </div>
                        </Box> : ""}

                    {mode === "view" ?
                        <>
                            <UserListToolbar id={"table-section"} numSelected={selected.length} filterName={filterName}
                                             onFilterName={handleFilterByName}/>
                            <Scrollbar>
                                {loading === true ?
                                    <div style={{width: "100%"}} className={"con-mid"}>
                                        <CircularProgress size={"2rem"}/>
                                    </div> :
                                    <TableContainer sx={{minWidth: 800}}>
                                        <Table>
                                            <UserListHead
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={TABLE_HEAD}
                                                rowCount={USERLIST.length}
                                                numSelected={selected.length}
                                                onRequestSort={handleRequestSort}
                                                onSelectAllClick={handleSelectAllClick}
                                            />
                                            <TableBody>
                                                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                    const {
                                                        first_name,
                                                        last_name,
                                                        provider,
                                                        id,
                                                        email,
                                                        confirmed,
                                                        blocked,
                                                        createdAt,
                                                        roleName
                                                    } = row;
                                                    const isItemSelected = selected.indexOf(id) !== -1;

                                                        return (
                                                            <TableRow
                                                                id={`tr_${id}`}
                                                                hover
                                                                key={id}
                                                                tabIndex={-1}
                                                                role="checkbox"
                                                                selected={isItemSelected}
                                                                aria-checked={isItemSelected}
                                                            >
                                                                <TableCell padding="checkbox">
                                                                    <Checkbox checked={isItemSelected}
                                                                              onChange={(event) => handleClick(event, id)}/>
                                                                </TableCell>
                                                                {/* <TableCell component="th" scope="row" padding="none">
                                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                                <Typography variant="subtitle2" noWrap>
                                                                    {Vehicle}
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell> */}

                                                                <TableCell align="left">{email}</TableCell>
                                                                <TableCell align="left">{`${first_name} ${last_name}`}</TableCell>
                                                                {/* <TableCell align="left">{provider}</TableCell> */}
                                                                <TableCell
                                                                    align="center">{confirmed === true ?
                                                                    <span className='true-badge'/> :
                                                                    <span className='false-badge'/>
                                                                }</TableCell>
                                                                <TableCell
                                                                    align="center">{blocked === false ?
                                                                    <span className='true-badge'/> :
                                                                    <span className='false-badge'/>
                                                                }</TableCell>
                                                                <TableCell align="center">
                                                                    <a href={`/dashboard/vehicle-admin/${id}`} className={"nav-sites-btn"} style={{
                                                                        textDecoration: "none",
                                                                        color: "#fff",
                                                                        border: "none"
                                                                    }}>Vehicles
                                                                    </a>
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left">{createdAt.split("T")[0]}</TableCell>

                                                                {/* <TableCell align="left">{Start.substring(0, Start.length - 7)}</TableCell> */}
                                                                {/* <TableCell align="left">{End.substring(0, End.length - 7)}</TableCell> */}

                                                                <TableCell align="right">
                                                                    <UserMoreMenu fieldId={{id, api}}/>
                                                                </TableCell>

                                                            </TableRow>
                                                        );
                                                })}
                    {emptyRows > 0 && (
                        <TableRow style={{height: 53 * emptyRows}}>
                            <TableCell colSpan={6}/>
                        </TableRow>
                    )}
                </TableBody>

                {isUserNotFound && (
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                <SearchNotFound searchQuery={filterName}/>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
}
</Scrollbar>

    <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={USERLIST.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />
</> :
    ""
}


</Card>
</Container>
</Page>
)
    ;
}
