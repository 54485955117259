import axios from 'axios';
import Keys from "../config/Keys";

const {API_URL} = Keys;

class StaticWebActions {
    // eslint-disable-next-line class-methods-use-this
    Home1() {
        return axios.get(`${API_URL}/api/home1`, {});
    }
}

export default new StaticWebActions();