import {useState} from 'react';
// form
// @mui
import {Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// components
import AuthActions from "../../../actions/AuthActions";

// ----------------------------------------------------------------------

export default function ForgotForm() {


    const [identifier, setIdentifier] = useState("");

    // eslint-disable-next-line
    const [status, setStatus] = useState(false);
    const [errors, setErrors] = useState("");
    const [loading, setLoading] = useState(false);

    const onChange = e => {
        const {value} = e.target;
        setIdentifier(value);
    };

    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setErrors("");

        AuthActions.ForgotPassword(identifier).then((res) => {
            setLoading(false);
            console.log(res);
            setStatus(true);
        }).catch(err => {
            setLoading(false);

            if (err.message === "Request failed with status code 429") {
                console.log("Rate limit exceeded");
            }

            const errorResponseMsg = err.response.data.error.message;
            setErrors(errorResponseMsg);

        })
    };


    return (
        <form onSubmit={onSubmit}>
            <Stack spacing={3}>
                <TextField name="identifier" id={"identifier"} label="Email address"
                           onChange={onChange}
                           value={identifier}
                           error={!!errors}
                           helperText={errors}
                           inputProps={{style: {fontSize: 13}}}
                           InputLabelProps={{style: {fontSize: 13}}}
                />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}/>

            <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained" className={"auth-btn"}>
                Send Verification
            </LoadingButton>
        </form>
    );
}
