import * as React from 'react';
import PropTypes from 'prop-types';

Home.propTypes = {
    title: PropTypes.node.isRequired,
};

export default function Home({title}) {

    return (
        <iframe src={"/website/home.html"} className="full-iframe"/>
    );
}
