// components
import Page from '../../components/Page';
import * as React from "react";
import {useEffect, useState} from "react";
import queryString from 'query-string'

// @mui
import {Container} from "@mui/material";
import UserActions from "../../actions/UserActions";

export default function CalUserBookingsVehicle() {

    const value = queryString.parse(location.search);
    const email = value.email;
    const name = value.name;

    const [vehicle, setVehicle] = React.useState('');
    const [allVehicles, setAllVehicles] = useState([]);

    const handleChange = (event) => {
        setVehicle(event.target.value);
        document.getElementById("vehicles").style.removeProperty("border");
        document.getElementById("helper-text").style.display = "none";
    };


    if (!(email || name) || email === "" || name === "") {
        document.location.href = "/login";
    }

    const bookingStep = () => {
        if (!vehicle) {
            document.getElementById("vehicles").style.border = "1px solid rgb(255, 0, 0)"
            document.getElementById("helper-text").style.removeProperty("display");
        } else {
            document.location.href = `/user/bookings?email=${email}&name=${name}&vehicle=${vehicle}`;
        }
    }

    useEffect(() => {
        UserActions.GetVehicle().then(res => {
            console.log(res.data.data);
            setAllVehicles(res.data.data)
        }).catch(err => {
            console.log(err);
        })
    }, [])

    return (
        <Page title="Bookings" style={{backgroundColor: "#101010", minHeight: "100vh"}}>
            <Container className={"cal"}>
                <div style={{padding: "50px 0"}}>

                    <div>
                        <h3 style={{color: "#fff", margin: "30px 20px 10px", textAlign: "center"}}>
                            User Booking
                        </h3>
                        <p style={{textAlign: "center", color: "#fff"}}>Vic Service Centre</p>
                    </div>

                    <div className={"con-mid"} style={{marginTop: "70px"}}>
                        <div className={"vehicle-block"} style={{padding: "35px 50px"}}>
                            <h5 style={{color: "#fff", marginBottom: "20px"}}>Select your vehicle</h5>

                            <span id={"helper-text"}
                                  style={{color: "rgba(255, 0, 0, 1)", fontSize: "10px", display: "none"}}>
                                Please select a vehicle
                            </span>
                            <select name="vehicles" onChange={handleChange} id="vehicles"
                                    className={"dash-vehicle-select"}>
                                <option value="">None</option>

                                {allVehicles.map((vehicle, index) => (
                                    <option key={index}
                                            value={vehicle.attributes.number}>{`${vehicle.attributes.brand} - ${vehicle.attributes.model} (${vehicle.attributes.number})`}</option>
                                ))}

                            </select>

                            <button onClick={bookingStep} className={"dash-vehicle-select-btn"}>Next</button>
                        </div>
                    </div>

                </div>
            </Container>
        </Page>
    );
}
