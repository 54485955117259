import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {Container, Link, Typography} from '@mui/material';
// hooks
import {LoadingButton} from "@mui/lab";
import * as React from "react";
import {useEffect, useState} from "react";
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import Iconify from "../components/Iconify";
import AuthActions from "../actions/AuthActions";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({theme}) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

// const SectionStyle = styled(Card)(({theme}) => ({
//     width: '100%',
//     maxWidth: 464,
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     margin: theme.spacing(2, 0, 2, 2),
// }));

const ContentStyle = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function EmailConfirmationCheck() {
    const smUp = useResponsive('up', 'sm');

    const navigate = useNavigate();
    const {search} = useLocation();
    const confirmation = new URLSearchParams(search).get('confirmation');

    // const mdUp = useResponsive('up', 'md');

    // const user = userData();

    const [status, setStatus] = useState("loading");

    useEffect(() => {
        AuthActions.EmailConfirmation(confirmation).then(res => {
            console.log(res);
            setStatus("verified")
        }).catch(err => {
            const errorStatus = err.response.status;

            if (errorStatus === 400) {
                setStatus("invalid")
            } else if (errorStatus === 404) {
                setStatus("verified");
            } else {
                console.log(errorStatus, err);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // if (user) {
    //     document.location.href = '/dashboard/inventory';
    // } else {

    const navigateLink = () => {
        navigate('/login')
    }

    return (
        <div className={"auth-page"}>
            <Page title="Login" className={"auth-container"} style={{backgroundImage: "url(/img/auth-bg.png)"}}>
                <RootStyle>
                    <HeaderStyle>
                        <Logo/>

                        {smUp && (
                            <Typography variant="body2" sx={{mt: {md: -2}}}>
                                Back to {' '}
                                <Link variant="subtitle2" component={RouterLink} to="/login">
                                    Sign in
                                </Link>
                            </Typography>
                        )}
                    </HeaderStyle>


                    <Container maxWidth="sm">
                        <ContentStyle>
                            <div className={"login-card"}>
                                {status === "loading" ?
                                    <>
                                        <Typography variant="h5" gutterBottom>
                                            <div className={"con-mid"}>
                                                <Iconify icon="eva:sync-outline"/>
                                                Validating...
                                            </div>
                                        </Typography>
                                    </> : ""}
                                {status === "verified" ?
                                    <>
                                        <Typography variant="h5" gutterBottom>
                                            <div className={"con-mid"}>
                                                <Iconify icon="eva:checkmark-outline"/>
                                                Email verified
                                            </div>
                                        </Typography>
                                        <br/>
                                        <LoadingButton onClick={navigateLink} fullWidth size="large" variant="contained"
                                                       className={"auth-btn"}>
                                            Continue
                                        </LoadingButton>
                                    </> : ""}
                                {status === "invalid" ?
                                    <>
                                        <Typography variant="h5" gutterBottom>
                                            <div className={"con-mid"}>
                                                <Iconify icon="eva:alert-triangle-outline"/>
                                                Invalid verification link
                                            </div>
                                        </Typography>
                                        <br/>
                                        <LoadingButton onClick={navigateLink} fullWidth size="large" variant="contained"
                                                       className={"auth-btn"}>
                                            Back to Login
                                        </LoadingButton>
                                    </> : ""}

                            </div>
                        </ContentStyle>

                    </Container>
                </RootStyle>
            </Page>
        </div>
    );
    // }
}
