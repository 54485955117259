import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {Container, Link, Typography} from '@mui/material';
// hooks
import {LoadingButton} from "@mui/lab";
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import AuthActions from "../actions/AuthActions";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({theme}) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

// const SectionStyle = styled(Card)(({theme}) => ({
//     width: '100%',
//     maxWidth: 464,
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     margin: theme.spacing(2, 0, 2, 2),
// }));

const ContentStyle = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function EmailConfirmation() {
    const navigate = useNavigate();
    const smUp = useResponsive('up', 'sm');

    const {search} = useLocation();
    const email = new URLSearchParams(search).get('email');


    // const mdUp = useResponsive('up', 'md');

    // const user = userData();

    const onSubmit = () => {
        AuthActions.ResendEmailConfirmation(email).then(res => {
            console.log(res);
            navigate(`/login`);
        }).catch(err => {
            console.log(err);
        })
    }


    // if (!user) {
    //     document.location.href = '/login';
    // } else {

        return (
            <div className={"auth-page"}>
                <Page title="Login" className={"auth-container"} style={{backgroundImage: "url(/img/auth-bg.png)"}}>
                    <RootStyle>
                        <HeaderStyle>
                            <Logo/>

                            {smUp && (
                                <Typography variant="body2" sx={{mt: {md: -2}}}>
                                    Back to {' '}
                                    <Link variant="subtitle2" component={RouterLink} to="/login">
                                        Sign in
                                    </Link>
                                </Typography>
                            )}
                        </HeaderStyle>


                        <Container maxWidth="sm">
                            <ContentStyle>
                                <div className={"login-card"}>
                                    <Typography variant="h5" gutterBottom>
                                        Pending email verification
                                    </Typography>
                                    <Typography sx={{color: 'text.secondary', mb: 5, fontSize: "14px"}}>
                                        Your verification email was sent during account registration, please check your mailbox.
                                    </Typography>

                                    <LoadingButton onClick={onSubmit} fullWidth size="large" type="submit" variant="contained"
                                                   className={"auth-btn"}>
                                        Resend
                                    </LoadingButton>


                                </div>
                            </ContentStyle>

                        </Container>
                    </RootStyle>
                </Page>
            </div>
        );
    // }
}
