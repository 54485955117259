import {Link as RouterLink} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {CircularProgress, Container, Link, Typography} from '@mui/material';
// hooks
import {useEffect, useState} from "react";
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import {LoginForm} from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';
import userData from "../config/CheckAuth";
import StaticWebActions from "../actions/StaticWebActions";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({theme}) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

// const SectionStyle = styled(Card)(({theme}) => ({
//     width: '100%',
//     maxWidth: 464,
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     margin: theme.spacing(2, 0, 2, 2),
// }));

const ContentStyle = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
    const smUp = useResponsive('up', 'sm');

    // const mdUp = useResponsive('up', 'md');

    const user = userData();


    const [backendLoading, setBackendLoading] = useState(false);

    useEffect(() => {
        const startBackend = () => {
            StaticWebActions.Home1().then((res) => {
                console.log(res.data.data.attributes.home1);
                setBackendLoading(true);
            }).catch(err => {
                console.log(err);
            })
        }
        startBackend();
    }, [])

    if (user) {
        document.location.href = '/dashboard/vehicle';
    } else {

        return (
            <div className={"auth-page"}>
                <Page title="Login" className={"auth-container"} style={{backgroundImage: "url(/img/auth-bg.png)"}}>
                    <RootStyle>
                        <HeaderStyle>
                            <Logo/>

                            {/* smUp && (
                                <Typography variant="body2" sx={{mt: {md: -2}}}>
                                    Don’t have an account? {''}
                                    <Link variant="subtitle2" component={RouterLink} to="/register">
                                        Get started
                                    </Link>
                                </Typography>
                            ) */}

                            <Typography variant="body2" sx={{mt: {md: -2}}}>
                                Don’t have an account? {''}
                                <Link variant="subtitle2" component={RouterLink} to="/register">
                                    Get started
                                </Link>
                            </Typography>

                        </HeaderStyle>


                        <Container maxWidth="sm">
                            <ContentStyle>
                                <div className={"login-card"}>
                                    <Typography variant="h5" gutterBottom>
                                        Sign in
                                    </Typography>

                                    <Typography sx={{color: 'text.secondary', mb: 5, fontSize: "14px"}}>to continue to
                                        Vic
                                        Car Service</Typography>

                                    {backendLoading === false ?
                                        <div style={{width: "100%", height: "100%"}} className={"con-mid"}>
                                            <CircularProgress size={"2rem"} />
                                        </div>
                                        :
                                    <>
                                         <AuthSocial/>
                                         <LoginForm/>
                                    </>}

                                    {!smUp && (
                                        <Typography variant="body2" align="center" sx={{mt: 3}}>
                                            Don’t have an account?{' '}
                                            <Link variant="subtitle2" component={RouterLink} to="/register">
                                                Get started
                                            </Link>
                                        </Typography>
                                    )}
                                </div>
                            </ContentStyle>

                        </Container>
                    </RootStyle>
                </Page>
            </div>
        );
    }
}
