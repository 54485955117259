// material
import {CircularProgress, Divider, Stack, Typography} from '@mui/material';
// component
// import Iconify from '../../components/Iconify';
import {gapi} from 'gapi-script';
import {useEffect, useState} from "react";
import GoogleLogin from "react-google-login";
import {useNavigate} from "react-router-dom";
import * as React from "react";
import AuthActions from "../../actions/AuthActions";
import Keys from "../../config/Keys";
import SettingsActions from "../../actions/SettingsActions";

// ----------------------------------------------------------------------

export default function AuthSocial() {
    const navigate = useNavigate();
    const clientId = Keys.GOOGLE_CLIENT_ID;

    const [loading, setLoading] = useState(false)

    const onSuccess = (res) => {
        setLoading(true);
        const {accessToken} = res;
        AuthActions.GoogleAuthCallback(accessToken).then((response) => {
            setLoading(false);
            console.log(response.data.jwt);
            localStorage.setItem("jwt", JSON.stringify(response.data.jwt));

            SettingsActions.updateUser(response.data.user.id, {
                first_name: res.profileObj.givenName,
                last_name: res.profileObj.familyName,
                image: res.profileObj.imageUrl
            }).then(() => {
                navigate('/dashboard/vehicle', {replace: true});
            }).catch((err) => {
                console.log(err);
            })

        }).catch(error => {
            console.log(error);
        })
        // console.log('success:', res);
    };
    const onFailure = (err) => {
        console.log('failed:', err);
    };

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    });
    return (
        <>
            <Stack direction="row" spacing={2}>
                <div className={"con-mid google-auth-div"} style={{width: "100%"}}>
                    {loading === true ?
                        <CircularProgress size={"2rem"} />
                        : <GoogleLogin
                        clientId={clientId}
                        buttonText={"Sign in with Google"}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn
                        className={"google-auth-btn"}
                    />}
                </div>

                {/* <Button fullWidth size="large" color="inherit" variant="outlined">
                  <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
                </Button>

                <Button fullWidth size="large" color="inherit" variant="outlined">
                  <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
                </Button> */}
            </Stack>
            {/* <Button fullWidth size="large" color="inherit" variant="outlined">
                <img src={"/icons/google.svg"} width={22} height={22} alt={"google-icon"}/>
                <span className={"social-login-text"}>Log in with Google</span>
            </Button> */}

            <Divider sx={{my: 3}}>
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    OR
                </Typography>
            </Divider>
        </>
    );
}
