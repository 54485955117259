import axios from 'axios';
import Keys from "../config/Keys";
// import apiHeader from "../config/ApiHeader";
import authHeader from "../config/AuthHeader";

const {API_URL} = Keys;

class UserActions {
    // eslint-disable-next-line class-methods-use-this
    GetComponents() {
        return axios.get(`${API_URL}/api/content-type-builder/components`, {headers: authHeader()});
    }

    // eslint-disable-next-line class-methods-use-this
    GetComponent(data) {
        return axios.get(`${API_URL}/api/content-type-builder/content-types/api::${data}.${data}`, {headers: authHeader()});
    }

    // eslint-disable-next-line class-methods-use-this
    // GetBookings(api) {
    //     return axios.get(`${API_URL}/api/${api}`, {headers: authHeader()});
    // }

    // eslint-disable-next-line class-methods-use-this
    Create(data, api) {
        console.log(data);
        return axios.post(`${API_URL}/api/${api}`, data, {headers: authHeader()});
    }

    // eslint-disable-next-line class-methods-use-this
    GetData(api) {
        return axios.get(`${API_URL}/api/${api}`, {headers: authHeader()});
    }

    // eslint-disable-next-line class-methods-use-this
    GetEditData(id, api) {
        return axios.get(`${API_URL}/api/${api}/${id}`, {headers: authHeader()});
    }

    // eslint-disable-next-line class-methods-use-this
    updateData(id, data, api) {
        return axios.put(`${API_URL}/api/${api}/${id}`, data,{headers: authHeader()});
    }

    // eslint-disable-next-line class-methods-use-this
    deleteData(id, api) {
        return axios.delete(`${API_URL}/api/${api}/${id}`,{headers: authHeader()});
    }



    // GetContentTypes() {
    //     return axios.get(`${API_URL}/api/content-type-builder/content-types`, {headers: authHeader()});
    // }


    //    CUSTOM

    // eslint-disable-next-line class-methods-use-this
    GetVehicle() {
        return axios.get(`${API_URL}/api/vehicles`, {headers: authHeader()});
    }

    // eslint-disable-next-line class-methods-use-this
    GetProducts() {
        return axios.get(`${API_URL}/api/inventories`, {headers: authHeader()});
    }

    //      BOOKING TIME SLOTS

    // eslint-disable-next-line class-methods-use-this
    GetBookingTimeSlots() {
        return axios.get(`${API_URL}/api/bookings`, {headers: {Authorization: `Bearer 67afaba18808b83978496da51b3be54ad63bb67f3f034135a5700e662a2f15d99f8f731ad35c8113104ef3159fd7b2b31bd36a084502cfb71f9b80f0ada7aa299f819d44997ef87aa82b87b879b4eb4b7ff4ea7bad4ea8e44c8955e5e57d2b54612c0e4e8585ed730d14f74e8f565a8e5a54d5839f818edd196bf45003720162`}});
    }

    // eslint-disable-next-line class-methods-use-this
    GetOffTime() {
        return axios.get(`${API_URL}/api/offtimes`, {headers: {Authorization: `Bearer 67afaba18808b83978496da51b3be54ad63bb67f3f034135a5700e662a2f15d99f8f731ad35c8113104ef3159fd7b2b31bd36a084502cfb71f9b80f0ada7aa299f819d44997ef87aa82b87b879b4eb4b7ff4ea7bad4ea8e44c8955e5e57d2b54612c0e4e8585ed730d14f74e8f565a8e5a54d5839f818edd196bf45003720162`}});
    }

    // eslint-disable-next-line class-methods-use-this
    fetchBookings(email) {
        return axios.get(
            `https://api.calendly.com/scheduled_events?user=https://api.calendly.com/users/e1375d70-b66e-463b-821b-81d634f4d639&invitee_email=${email}&status=active&min_start_time=${new Date()}`,
            {headers:
                    {Authorization: `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjcwNzQxMjUxLCJqdGkiOiI2ZWI2N2QzNC1jYTYwLTRiMDgtYmY4OS0yMTFjZTIxMzkwMmUiLCJ1c2VyX3V1aWQiOiJlMTM3NWQ3MC1iNjZlLTQ2M2ItODIxYi04MWQ2MzRmNGQ2MzkifQ.PZn9FHnB2AAlbQ4AFLZ0z_cM8kECJeDg8SSFIZIOfDMIo1VPXkf2PbVU_v1wohHpYyoS1BFwf30u0uyI1EQGUw`}});
    }

    // eslint-disable-next-line class-methods-use-this
    fetchHistoryBookings(email) {
        return axios.get(
            `https://api.calendly.com/scheduled_events?user=https://api.calendly.com/users/e1375d70-b66e-463b-821b-81d634f4d639&invitee_email=${email}`,
            {headers:
                    {Authorization: `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjcwNzQxMjUxLCJqdGkiOiI2ZWI2N2QzNC1jYTYwLTRiMDgtYmY4OS0yMTFjZTIxMzkwMmUiLCJ1c2VyX3V1aWQiOiJlMTM3NWQ3MC1iNjZlLTQ2M2ItODIxYi04MWQ2MzRmNGQ2MzkifQ.PZn9FHnB2AAlbQ4AFLZ0z_cM8kECJeDg8SSFIZIOfDMIo1VPXkf2PbVU_v1wohHpYyoS1BFwf30u0uyI1EQGUw`}});
    }

    // eslint-disable-next-line class-methods-use-this
    fetchBooking(url) {
        return axios.get(`${url}/invitees`, {headers:
        {Authorization: `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjcwNzQxMjUxLCJqdGkiOiI2ZWI2N2QzNC1jYTYwLTRiMDgtYmY4OS0yMTFjZTIxMzkwMmUiLCJ1c2VyX3V1aWQiOiJlMTM3NWQ3MC1iNjZlLTQ2M2ItODIxYi04MWQ2MzRmNGQ2MzkifQ.PZn9FHnB2AAlbQ4AFLZ0z_cM8kECJeDg8SSFIZIOfDMIo1VPXkf2PbVU_v1wohHpYyoS1BFwf30u0uyI1EQGUw`}});
    }

    // eslint-disable-next-line class-methods-use-this
    GetEventNotes() {
        return axios.get(`${API_URL}/api/event-records`, {headers: authHeader()});
    }

    // eslint-disable-next-line class-methods-use-this
    CreateEventNotes(data) {
        return axios.post(`${API_URL}/api/event-records`, data, {headers: authHeader()});
    }

    // eslint-disable-next-line class-methods-use-this
    UpdateEventNotes(id, data) {
        return axios.put(`${API_URL}/api/event-records/${id}`, data, {headers: authHeader()});
    }


}

export default new UserActions();