// material
import {Stack, Divider, Typography, CircularProgress} from '@mui/material';
import {useEffect, useState} from "react";
import {gapi} from "gapi-script";
import {useNavigate} from "react-router-dom";
import GoogleLogin from "react-google-login";
import * as React from "react";
import AuthActions from "../../actions/AuthActions";
import Keys from "../../config/Keys";
import SettingsActions from "../../actions/SettingsActions";
// component
// import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function SignUpSocial() {
    const navigate = useNavigate();
    const clientId = Keys.GOOGLE_CLIENT_ID;
    const [loading, setLoading] = useState(false)


    const onSuccess = (res) => {
        // console.log(res.profileObj.givenName, res.profileObj.familyName, res.profileObj.imageUrl);
        setLoading(true);
        const {accessToken} = res;
        AuthActions.GoogleAuthCallback(accessToken).then((response) => {
            // console.log(response.data.jwt);
            setLoading(false);
            localStorage.setItem("jwt", JSON.stringify(response.data.jwt));

            SettingsActions.updateUser(response.data.user.id, {first_name: res.profileObj.givenName, last_name: res.profileObj.familyName, image: res.profileObj.imageUrl}).then(() => {
                // console.log(r);
                navigate('/dashboard/vehicle', {replace: true});
            }).catch((err) => {
                console.log(err);
            })


        }).catch(error => {
            console.log(error);
        })
        // console.log('success:', res);
    };
    const onFailure = (err) => {
        console.log('failed:', err);
    };

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    });
    return (
        <>
            <Stack direction="row" spacing={2}>
                <div className={"con-mid google-auth-div"} style={{width: "100%"}}>
                {loading === true ?
                    <CircularProgress size={"2rem"} />
                    :
                    <GoogleLogin
                    clientId={clientId}
                    buttonText="Sign up with Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn
                    className={"google-auth-btn"}
                />}
                </div>

                {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
        </Button>

        <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
        </Button> */}
            </Stack>

            <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    OR
                </Typography>
            </Divider>
        </>
    );
}
