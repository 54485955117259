// @mui
import {Container} from '@mui/material';
// components
import Page from '../../components/Page';
import * as React from "react";
import {useEffect} from "react";
import queryString from 'query-string'
import {InlineWidget} from "react-calendly";


export default function CalUserBookings() {

    const value = queryString.parse(location.search);
    const email = value.email;
    const name = value.name;
    const vehicle = value.vehicle;

    let calLink = "vic-service-centre-n0pv4a";

    if (!(email || name) || email === "" || name === "") {
        document.location.href = "/login";
    }

    const isCalendlyEvent = (e) => {
        return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
    }

    const backToDashboard = () => {
        document.location.href = `/dashboard/vehicle`;
    }

    useEffect(() => {
        window.addEventListener("message", function (e) {
            if (isCalendlyEvent(e)) {
                /* Example to get the name of the event */
                console.log("Event name:", e.data.event);

                if (e.data.event === 'calendly.event_scheduled') {
                    document.getElementById('back_to_dashboard').style.removeProperty("display")
                }

                /* Example to get the payload of the event */
                console.log("Event details:", e.data.payload);
            }
        });

        // console.log(document.body.contains(document.getElementsByName("question_0")[0]));
        // document.getElementsByName("question_0")[0].setAttribute("disabled", "disabled");
    }, [])


    return (
        <Page title="Bookings" style={{backgroundColor: "#101010", minHeight: "100vh"}}>
            <Container className={"cal"}>
                <div style={{padding: "50px 0"}}>

                    <div>
                        <h3 style={{color: "#fff", margin: "30px 20px 10px", textAlign: "center"}}>
                            User Booking
                        </h3>
                        <p style={{textAlign: "center", color: "#fff"}}>Vic Service Centre</p>
                    </div>

                    <div className={"con-mid"}>
                        <button
                            id={'back_to_dashboard'}
                            style={{width: "240px", display: "none"}}
                            onClick={backToDashboard}
                            className={"dash-vehicle-select-btn"}
                        >Back to dashboard
                        </button>
                    </div>

                    <InlineWidget
                        styles={{
                            height: '700px'
                        }}

                        disabled

                        prefill={{
                            email: email,
                            name: name,
                            customAnswers: {
                                a1: vehicle
                            }
                        }}

                        url="https://calendly.com/vic-service?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=1c1c1c&text_color=ffffff&primary_color=2bc8b0"
                    />

                </div>
            </Container>
        </Page>
    );
}
