import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
// material
import {styled} from '@mui/material/styles';
import {Box, CircularProgress, Drawer} from '@mui/material';
// mock
// import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
// import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
// import navConfig from './NavConfig';
import UserActions from "../../actions/UserActions";

// component
import Iconify from '../../components/Iconify';
import SettingsActions from "../../actions/SettingsActions";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22}/>;


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({theme}) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
    },
}));

// const AccountStyle = styled('div')(({theme}) => ({
//     display: 'flex',
//     alignItems: 'center',
//     padding: theme.spacing(2, 2.5),
//     borderRadius: Number(theme.shape.borderRadius) * 1.5,
//     backgroundColor: theme.palette.grey[500_12],
// }));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({isOpenSidebar, onCloseSidebar}) {

    const [loading, setLoading] = useState(false);
    const {pathname} = useLocation();
    const isDesktop = useResponsive('up', 'lg');
    const [navConfig, setNavConfig] = useState([]);
    // eslint-disable-next-line
    const [role, setRole] = useState();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    useEffect(() => {
        setLoading(true);
        SettingsActions.me().then(userRes => {
            const role = userRes.data.roleName;
            setRole(role);
            UserActions.GetComponents().then((res) => {
                const tempNavConfig = [];
                const components = res.data.data;
                for (let i = 0; i < components.length; i += 1) {
                    if (components[i].category === role) {
                        tempNavConfig.push({
                            title: components[i].schema.displayName,
                            path: `/dashboard/${components[i].apiId}`,
                            icon: getIcon(`eva:${components[i].schema.icon}-outline`)
                        });
                    }
                    if (i === components.length - 1) {
                        setNavConfig(tempNavConfig);
                        setLoading(false);

                    }
                }
            }).catch(err => {
                console.log(err);
            })
        }).catch(userErr => {
            console.log(userErr);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {height: 1, display: 'flex', flexDirection: 'column'},
            }}
        >
            <Box sx={{px: 2.5, py: 3, display: 'inline-flex'}} className={"dash-side-1"}>
                <div className={"gammabase-brand"}>
                    {/* <strong>Vic Logo</strong> */}
                    <img className={"dashboard-logo"} src={"/logo/vic-long-logo.png"} alt={"logo"} />
                </div>
            </Box>

            <NavSection navConfig={navConfig} className={"sidebar"}/>

            <Box sx={{flexGrow: 1}}/>

        </Scrollbar>
    );

    return (
        <RootStyle>

            {!isDesktop && (
                loading === true ?
                    <div className={"con-mid"} style={{height: "100vh", width: "100%"}}>
                        <CircularProgress size={"2rem"} />
                    </div>
                    :
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: {
                            bgcolor: "#e6e6e6",
                            width: DRAWER_WIDTH
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                loading === true ?
                    <div className={"con-mid"} style={{height: "100vh", width: "100%"}}>
                        <CircularProgress size={"2rem"} />
                    </div>
                    :
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'transparent',
                            borderWidth: "0",
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}
