import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
// form
// @mui
import {IconButton, InputAdornment, Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';

import AuthActions from '../../../actions/AuthActions';

// ----------------------------------------------------------------------

export default function RegisterForm() {

    const navigate = useNavigate();
    const schema = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        mobile_number: "",
        address: ""
    }
    const [showPassword, setShowPassword] = useState(false);
    const [state, setState] = useState(schema);
    const [errors, setErrors] = useState(schema);
    const [loading, setLoading] = useState(false);

    const onChange = e => {
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };

    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setErrors(schema);
        Object.assign(state, {username: state.email})

        AuthActions.CreateUser(state).then((r) => {
            console.log(r);
                    setLoading(false);
            navigate('/login', {replace: true});
        }).catch(err => {
                    setLoading(false);

            if (err.message === "Request failed with status code 429") {
                console.log("Rate limit exceeded");
            }

            const errorResponseMsg = err.response.data.error.message;
            const errorResponse = err.response.data.error.details.errors;

            let tempErrors = {};

            if (state.first_name === "") {
                tempErrors = Object.assign(tempErrors, {first_name: "first name is a required field"});
            }

            if (state.last_name === "") {
                tempErrors = Object.assign(tempErrors, {last_name: "last name is a required field"});
            }

            if (state.address === "") {
                tempErrors = Object.assign(tempErrors, {address: "Address is a required field"});
            }

            if (state.mobile_number === "") {
                tempErrors = Object.assign(tempErrors, {mobile_number: "Mobile Number is a required field"});
            }

            if (errorResponseMsg === "Email or Username are already taken") {
                tempErrors = Object.assign(tempErrors, {email: "email is already taken"});
                setErrors(tempErrors);
            } else {

                const updateError = (item, i) => {
                    if (item.path[0] === "first_name" && item.message === "first_name must be at least 1 characters") {
                        tempErrors = Object.assign(tempErrors, {first_name: "first name is a required field"});
                    } else if (item.path[0] === "last_name" && item.message === "last_name must be at least 1 characters") {
                        tempErrors = Object.assign(tempErrors, {last_name: "last name is a required field"});
                    } else if (item.path[0] === "address" && item.message === "address must be at least 1 characters") {
                        tempErrors = Object.assign(tempErrors, {address: "address is a required field"});
                    } else if (item.path[0] === "mobile_number" && item.message === "mobile_number must be at least 1 characters") {
                        tempErrors = Object.assign(tempErrors, {mobile_number: "mobile number is a required field"});
                    } else {
                        const data0 = {[item.path[0]]: item.message};
                        tempErrors = Object.assign(tempErrors, data0);
                    }
                    if (i === errorResponse.length - 1) {
                        setErrors(tempErrors);
                    }
                }
                errorResponse.forEach(updateError)
            }

        })
    };

    return (
        <form onSubmit={onSubmit}>
            <Stack spacing={3}>
                <TextField name="first_name" label="First name"
                           id={"first_name"}
                           value={state.first_name}
                           inputProps={{style: {fontSize: 13}}}
                           InputLabelProps={{style: {fontSize: 13}}}
                           onChange={onChange}
                           error={!!errors.first_name}
                           helperText={errors.first_name}
                />
                <TextField name="last_name" label="Last name"
                           id={"last_name"}
                           onChange={onChange}
                           value={state.last_name}
                           inputProps={{style: {fontSize: 13}}}
                           InputLabelProps={{style: {fontSize: 13}}}
                           error={!!errors.last_name}
                           helperText={errors.last_name}
                />

                <TextField name="email" label="Email address"
                           id={"email"}
                           onChange={onChange}
                           value={state.email}
                           inputProps={{style: {fontSize: 13}}}
                           InputLabelProps={{style: {fontSize: 13}}}
                           error={!!errors.email}
                           helperText={errors.email}
                />

                <TextField name="mobile_number" label="Mobile Number"
                           id={"mobile_number"}
                           onChange={onChange}
                           value={state.mobile_number}
                           inputProps={{style: {fontSize: 13}}}
                           InputLabelProps={{style: {fontSize: 13}}}
                           error={!!errors.mobile_number}
                           helperText={errors.mobile_number}
                />

                <TextField name="address" label="Address"
                           id={"address"}
                           onChange={onChange}
                           value={state.address}
                           inputProps={{style: {fontSize: 13}}}
                           InputLabelProps={{style: {fontSize: 13}}}
                           error={!!errors.address}
                           helperText={errors.address}
                />

                <TextField
                    name="password"
                    label="Password"
                    id={"password"}
                    type={showPassword ? 'text' : 'password'}
                    onChange={onChange}
                    value={state.password}
                    InputProps={{
                        style: {fontSize: 13},
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{style: {fontSize: 13}}}
                    error={!!errors.password}
                    helperText={errors.password}
                />

                <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained" className={"auth-btn"}>
                    Sign up
                </LoadingButton>
            </Stack>
        </form>
    );
}
