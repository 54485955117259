import * as React from 'react';
import {useEffect, useState} from 'react';
import {filter} from 'lodash';
import PropTypes from 'prop-types';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material/styles';
// material
import {
    Avatar,
    Box,
    Button,
    Card,
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import {UserMoreMenu} from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';
import UserActions from "../actions/UserActions";
import SettingsActions from "../actions/SettingsActions";
import DashboardNavbar from "../layouts/dashboard/DashboardNavbar";
import user from "../_mock/user";
import BookingsList from "./BookingsList";
import account from "../_mock/account";


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    {id: 'number', label: 'number', alignRight: false},
    {id: 'brand', label: 'brand', alignRight: false},
    {id: 'model', label: 'model', alignRight: false},
    // {id: 'Time', label: 'Time', alignRight: false},
    // {id: 'status', label: 'Status', alignRight: false},
    {id: ''},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.Vehicle.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

Vehicle.propTypes = {
    component: PropTypes.node.isRequired,
    api: PropTypes.node.isRequired,
    mode: PropTypes.node.isRequired
};

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export default function Vehicle({component, api, mode}) {

    const [curtain, setCurtain] = useState(true);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");

    const [checkNull, setNull] = useState(false);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [loading, setLoading] = useState(false);

    const [userData, setUserData] = useState({});

    // const role = "admin";
    const [role, setRole] = useState();
    const navigate = useNavigate();

    const schema = {
        number: "",
        brand: "",
        model: "",
        year: "",
        colour: "",
        fuel_type: "",
        gear_box: "",
        engine_no: "",
        speedo_reading: "",
        delivery_promised: "",
        price_quoted: 0,
        notes: ""
    }

    const [data, setData] = useState(schema);
    const [errors, setErrors] = useState(schema);
    const onChange = e => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };
    const handleDateChange = (e) => {
        const {name, value} = e.target;
        console.log(name, value);
        setData({...data, [name]: value});
    };
    const handleTimeChange = (e) => {
        const {name, value} = e.target;
        const formattedValue = `${value}:00.000`;
        console.log(name, formattedValue);
        setData({...data, [name]: formattedValue});
    };

    const location = useLocation();

    const [USERLIST, SETUSERLIST] = useState([]);

    const [slug, setSlug] = useState();

    const [editId, setEditId] = useState();

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [title, setTitle] = useState("");

    const [attributes, setAttributes] = useState([]);

    const [vehicles, setVehicles] = useState([]);

    const [bookings, setBookings] = useState([]);
    const [bookingsOn, setBookingsOn] = useState([]);

    const [APILoading, setAPILoading] = useState(true);

    const [vehicleEmpty, setVehicleEmpty] = useState(false);


    // const label = {inputProps: {'aria-label': 'Color switch demo'}};

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    const errorFunc = (err) => {
        console.log(err);

        if (err.message === "Request failed with status code 429") {
            console.log("Rate limit exceeded");
        }

        const errorResponseMsg = err.response.data.error.message;
        const errorResponse = err.response.data.error.details.errors;

        let tempErrors = {};
        const updateError = (item, i) => {
            const data0 = {[item.path[0]]: item.message};
            tempErrors = Object.assign(tempErrors, data0);

            if (i === errorResponse.length - 1) {
                setErrors(tempErrors);
            }
        }

        if (!errorResponse) {
            if (errorResponseMsg) {
                if (errorResponseMsg === "Invalid date") {

                    console.log(errorResponseMsg);

                    let tempErrors = {};
                    for (let i = 0; i < attributes.length; i += 1) {
                        if (attributes[i][1].type === "date") {
                            const data0 = {[attributes[i][0]]: errorResponseMsg};
                            tempErrors = Object.assign(tempErrors, data0);
                        }

                        if (i === attributes.length - 1) {
                            setErrors(tempErrors);
                        }
                    }

                } else if (errorResponseMsg === "Invalid time format, expected HH:mm:ss.SSS") {

                    console.log(errorResponseMsg);

                    let tempErrors = {};
                    for (let i = 0; i < attributes.length; i += 1) {
                        if (attributes[i][1].type === "time") {
                            const data0 = {[attributes[i][0]]: errorResponseMsg};
                            tempErrors = Object.assign(tempErrors, data0);
                        }

                        if (i === attributes.length - 1) {
                            setErrors(tempErrors);
                        }
                    }

                } else {
                    console.log(errorResponseMsg);
                }
            }
        } else {
            console.log(errorResponse);
            errorResponse.forEach(updateError)
        }
    }

    const onCreateSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setErrors(schema);

        if (mode === "create") {
            await UserActions.Create({data}, api).then((r) => {
                setLoading(false);
                console.log(r);
                navigate('/dashboard/vehicle', {replace: true});
            }).catch(err => {
                setLoading(false);
                errorFunc(err);
            })
        } else if (mode === "edit") {
            await UserActions.updateData(editId, {data}, api).then((r) => {
                setLoading(false);
                console.log(r);
                navigate('/dashboard/vehicle', {replace: true});
            }).catch(err => {
                setLoading(false);
                errorFunc(err);
            })
        }

    };

    // eslint-disable-next-line
    const [schemaArray, setSchemaArray] = useState([]);


    // history model
    const [historyModel, setHistoryModel] = useState(false);

    const [currentVehicle, setCurrentVehicle] = useState("");

    const [sideLoading, setSideLoading] = useState(false);

    const onOpen = async (number) => {
        setVehicleEmpty(false);
        if (APILoading === false && bookings.find(item => item.vehicle === number) === undefined) {

            setVehicleEmpty(true);

        } else if (APILoading === true) {

            let notEmpty = 0;

            SettingsActions.me().then(async userRes => {
                await UserActions.fetchHistoryBookings(userRes.data.email).then(async resData => {
                    let events = resData.data.collection;

                    if (events.length === 0) {setSideLoading(false);}

                    for (let i = 0; i < events.length; i++) {
                        await UserActions.fetchBooking(events[i].uri).then(async res2 => {
                            if (number === res2.data.collection[0].questions_and_answers[0].answer) {
                                notEmpty = 1;
                            }
                        }).catch(err2 => {
                            console.log(err2);
                        })

                        console.log(notEmpty);
                        if (i === events.length - 1 && notEmpty === 0) {
                            setVehicleEmpty(true);
                            setSideLoading(false);
                        }
                    }
                }).catch(err => {
                    console.log(err);
                })
            }).catch(err3 => {
                console.log(err3);
            })

        }

        setCurrentVehicle(number);
        // setHistoryModel(true);
    }

    const onClose = () => {
        setVehicleEmpty(false);
        setHistoryModel(false);
    }
    // -- end - history model


    useEffect(() => {
        setSideLoading(true);
        SettingsActions.me().then(async userRes => {

            console.log(userRes.data);
            setUserData(userRes.data);

            setRole(userRes.data.roleName);
            setCurtain(false);
            if (userRes.data.roleName === "admin") {
                navigate('/dashboard/inventory', {replace: true});
            }

            await setUserName(userRes.data.first_name + " " + userRes.data.last_name);
            await setUserEmail(userRes.data.email);

            await UserActions.fetchHistoryBookings(userRes.data.email).then(async resData => {
                let events = resData.data.collection;
                if (events.length === 0) {setSideLoading(false);}
                for (let i = 0; i < events.length; i++) {
                    await UserActions.fetchBooking(events[i].uri).then(async res2 => {
                        events[i].vehicle = res2.data.collection[0].questions_and_answers[0].answer;
                    }).catch(err2 => {
                        console.log(err2);
                    })
                    if (i === events.length - 1) {
                        setAPILoading(false);
                        setBookings(events);
                        setSideLoading(false);
                    }
                }
            }).catch(err => {
                console.log(err);
            })


        }).catch(userErr => {
            console.log(userErr);
        })
    }, [])


    useEffect(() => {

        // console.log(userName);
        // console.log(userEmail);

        let count = 0;
        const tempSchemaArray = [];

        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const i in schema) {
            count += 1;
            tempSchemaArray.push(i);

            if (Object.keys(schema).length === count) {
                setSchemaArray(tempSchemaArray);
            }

        }

        const pageDataLoader = (slugParam) => {
            UserActions.GetComponent(slugParam).then((res) => {

                const resAttr = res.data.data.schema.attributes;
                setAttributes(Object.entries(resAttr));
                setTitle(res.data.data.schema.displayName);

            }).catch(err => {
                console.log(err);
            })
        }

        const editItemLoader = (editIdParam) => {
            UserActions.GetEditData(editIdParam, api).then((res) => {
                setData(res.data.data.attributes);
            }).catch(err => {
                navigate('/login', {replace: true});
                console.log(err);
            })
        }

        const dataFetch = () => {
            setLoading(true);

            UserActions.GetData(api).then((res) => {
                const fetchedData = res.data.data;
                const tempDataArray = [];
                setLoading(false);

                setVehicles(fetchedData);

                for (let i = 0; i < fetchedData.length; i += 1) {
                    Object.assign(fetchedData[i].attributes, {"id": fetchedData[i].id})
                    tempDataArray.push(fetchedData[i].attributes);
                    if (i === fetchedData.length - 1) {
                        SETUSERLIST(tempDataArray);
                    }
                }

            }).catch(err => {
                // navigate('/login', {replace: true});
                console.log(err);
            })
        }

        if (mode === "edit") {

            const slugParam = component;
            const editIdParam = location.pathname.split('/').pop();

            // setMode(operation);
            setSlug(slugParam);
            setEditId(editIdParam);

            pageDataLoader(slugParam);
            editItemLoader(editIdParam);

        } else if (mode === "view") {
            setData(schema);
            const slugParam = component;
            setSlug(slugParam)
            pageDataLoader(slugParam);
            dataFetch();
        } else if (mode === "create") {
            setData(schema);
            const slugParam = component;
            setSlug(slugParam)
            pageDataLoader(slugParam);
            dataFetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, navigate])

    // bookings list
    useEffect(() => {
        SettingsActions.me().then(async userRes => {
            setRole(userRes.data.roleName);
            setCurtain(false);
            if (userRes.data.roleName === "admin") {
                navigate('/dashboard/inventory', {replace: true});
            }

            await setUserName(userRes.data.first_name + " " + userRes.data.last_name);
            await setUserEmail(userRes.data.email);

            await UserActions.fetchBookings(userRes.data.email).then(async resData => {
                let events = resData.data.collection;
                for (let i = 0; i < events.length; i++) {
                    await UserActions.fetchBooking(events[i].uri).then(async res2 => {
                        events[i].vehicle = res2.data.collection[0].questions_and_answers[0].answer;
                    }).catch(err2 => {
                        console.log(err2);
                    })
                    if (i === events.length - 1) {
                        setAPILoading(false);
                        setBookingsOn(events);
                    }
                }

                if (events.length === 0) {
                    setNull(true);
                } else {
                    setNull(false);
                }
            }).catch(err => {
                console.log(err);
            })

        }).catch(userErr => {
            console.log(userErr);
        })


    }, [])

    const backToUpcoming = () => {
        setCurrentVehicle("");
    }

    const toSettings = () => {
        window.location.href = "/dashboard/settings/";
    }

    return (
        <>

            {historyModel === true ?
                <div className={"dash-vehicle-history"}>
                    <div onClick={onClose} id={"history-close-btn"} className={"history-close-btn"}>x</div>
                    <div style={{width: "100%", height: "100%"}} className={"con-mid"}>
                        <div className={"history-back"}>

                            {APILoading === true ?
                                <div style={{width: "100%", height: "100%"}} className={"con-mid"}>
                                    <CircularProgress style={{color: "#fff"}} size={"2rem"}/>
                                </div> : ""}

                            {bookings.map((booking, index) => (

                                <>
                                    {/*
                                        vehicleEmpty === true ?
                                            <div key={index} style={{width: "100%", height: "100%", color: "#fff"}}
                                                 className={"con-mid"}>No
                                                records
                                                found</div> : ""
                                    */}

                                    {
                                        booking.vehicle === currentVehicle ?

                                            <div className={"dash-bookings-blocks"} key={index} id={`booking_${index}`}>

                                                <div key={`2_ ${index}`} className={"row"}>
                                                    <div className={"col"}>
                                                        {`${booking.status} - `}
                                                        <span className={"dash-booking-type"}>{`${booking.name}`}</span>
                                                    </div>
                                                </div>
                                                <div key={`3_ ${index}`} className={"row"} style={{marginTop: '7px'}}>
                                                    <div className={"col"}>
                                                        {booking.start_time.split('T')[0]}
                                                        &emsp;|&emsp;
                                                        {`${booking.start_time.split('T')[1].split('.')[0]} - ${booking.end_time.split('T')[1].split('.')[0]}`}
                                                    </div>
                                                </div>

                                            </div> : ""
                                    } </>

                            ))}

                        </div>
                    </div>
                </div> : ""}

            <DashboardNavbar onOpenSidebar={() => setOpen(true)}/>
            <style>{
                `body {
                    background-color: rgb(16, 16, 16) !important;
                    color: #fff;
                 }
                 .dash-card {
                    box-shadow: none;
                 }
                 .more-dots {
                    color: #fff;
                 }
                 .dash-nav-2 {
                    background-color: rgba(16, 16, 16, .5);
                 }
                 .nav-username {
                    color: #fff;
                 }
                `}</style>
            <Page title={title} className={"user-dash-page"}>
                {
                    loading === true && curtain === false ?
                        <div className={"loading-bar"}>
                            <LinearProgress/>
                        </div> : ""
                }
                {
                    sideLoading === true ?
                        <div className={"loading-bar"}>
                            <LinearProgress/>
                        </div> : ""
                }
                {
                    curtain === true ?
                        <div className={"curtain con-mid"}>
                            <div style={{display: "inline-block"}}>
                                <CircularProgress size={"1rem"} style={{color: "#fff"}}/>
                                <span>
                            Loading
                        </span>
                            </div>
                        </div> : ""
                }
                <Container className={"user-dash-container"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>

                            {/* <RouterLink to={"/dashboard/vehicle"}
                                         className={"dash-primary-tab"}>▶ &nbsp;&nbsp;My {title}</RouterLink>

                                <RouterLink to={"/dashboard/bookings"} className={"dash-secondary-tab"}>My
                                bookings</RouterLink> */}
                            <div>
                                <Avatar style={{marginBottom: "10px"}}
                                        src={userData.image ? userData.image : account.photoURL} alt="photoURL"/>
                                <h5>{userData.first_name} {userData.last_name}</h5>
                            </div>

                        </Typography>


                        <Button className={"dash-booking-btn dash-booking-btn-control"} variant="contained"
                                startIcon={<Iconify icon="eva:plus-fill"/>}
                                component={RouterLink} to={`/user/bookings-vehicle?email=${userEmail}&name=${userName}`}
                            // onClick={handleOpen}
                        >
                            New booking
                        </Button>

                    </Stack>

                    <div className={"container mobile-booking-btn"}>
                        <Button className={"dash-booking-btn"} style={{width: "100%"}} variant="contained"
                                startIcon={<Iconify icon="eva:plus-fill"/>}
                                component={RouterLink} to={`/user/bookings-vehicle?email=${userEmail}&name=${userName}`}
                            // onClick={handleOpen}
                        >
                            New booking
                        </Button>
                    </div>

                    <Card className={"dash-card"}>

                        {mode === "create" || mode === "edit" ?
                            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                                <div className={"form-helper-div"}>

                                    <fieldset>
                                        <ThemeProvider theme={darkTheme}>
                                            <legend
                                                style={{
                                                    color: "#fff",
                                                    textTransform: "capitalize"
                                                }}>{mode === "edit" ? `Update ${slug}` : `New ${slug}`}</legend>

                                            {attributes.map((attribute, index) => (
                                                // eslint-disable-next-line no-nested-ternary
                                                attribute[0] === "user" ||
                                                attribute[0] === "colour" ||
                                                attribute[0] === "engine_no" ||
                                                attribute[0] === "speedo_reading" ||
                                                attribute[0] === "delivery_promised" ||
                                                attribute[0] === "price_quoted" ||
                                                attribute[0] === "notes"
                                                    ? "" :
                                                    // eslint-disable-next-line no-nested-ternary
                                                    attribute[1].type === "string" ?
                                                        <TextField
                                                            label={attribute[0]}
                                                            type={"text"}
                                                            name={attribute[0]}
                                                            // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                            sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                            variant={"outlined"}
                                                            key={index}
                                                            inputProps={{style: {fontSize: 13}}}
                                                            InputLabelProps={{style: {fontSize: 13}}}
                                                            value={data[attribute[0]]}
                                                            onChange={onChange}
                                                            error={!!errors[attribute[0]]}
                                                            helperText={errors[attribute[0]]}
                                                        />
                                                        :
                                                        // eslint-disable-next-line no-nested-ternary
                                                        attribute[1].type === "enumeration" ?
                                                            <FormControl variant="outlined"
                                                                         key={index}
                                                                // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                                         sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                            >
                                                                <InputLabel
                                                                    id={`demo-simple-select-standard-label-${index}`}
                                                                    style={errors[attribute[0]] ? {
                                                                        fontSize: 13,
                                                                        color: "#FF4842"
                                                                    } : {fontSize: 13}}
                                                                >{attribute[0]}</InputLabel>
                                                                <Select
                                                                    labelId={`demo-simple-select-standard-label-${index}`}
                                                                    name={attribute[0]}
                                                                    label={attribute[0]}
                                                                    style={{fontSize: 13}}
                                                                    value={data[attribute[0]]}
                                                                    onChange={onChange}
                                                                    error={!!errors[attribute[0]]}
                                                                    // helpertext={errors[attribute[0]]}
                                                                >
                                                                    <MenuItem style={{display: 'none'}}>
                                                                        <span style={{color: "transparent"}}>_</span>
                                                                    </MenuItem>
                                                                    {attribute[1].enum.map((item, itemIndex) => (
                                                                        <MenuItem key={itemIndex}
                                                                                  value={item}>{item}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {errors[attribute[0]] ? <span
                                                                    className={"error-helper"}>{errors[attribute[0]]}</span> : ""}
                                                            </FormControl>
                                                            :
                                                            // eslint-disable-next-line no-nested-ternary
                                                            attribute[1].type === "date" ?
                                                                <FormControl variant="outlined"
                                                                             key={index}
                                                                    // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                                             sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                                >
                                                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                                                                    {/*    <Stack spacing={3}> */}

                                                                    {/* <DesktopDatePicker
                                                                    id={attribute[0]}
                                                                    name={attribute[0]}
                                                                    variant={"outlined"}
                                                                    label={attribute[0]}
                                                                    inputFormat="DD/MM/YYYY"
                                                                    // value={value}
                                                                    onChange={(value) => handleDateChange(attribute[0], value)}
                                                                    renderInput={(params) =>
                                                                        <TextField {...params} />}
                                                                    inputProps={{style: {fontSize: 13}}}
                                                                    InputLabelProps={{style: {fontSize: 13}}}
                                                                    value={data[attribute[0]]}
                                                                    // onChange={onChange}
                                                                    error={!!errors[attribute[0]]}
                                                                    helperText={errors[attribute[0]]}
                                                                /> */}

                                                                    <TextField
                                                                        name={attribute[0]}
                                                                        id={attribute[0]}
                                                                        label={attribute[0]}
                                                                        type="date"
                                                                        // defaultValue=""
                                                                        value={data[attribute[0]]}
                                                                        onChange={(e) => handleDateChange(e)}
                                                                        inputProps={{style: {fontSize: 13}}}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                            style: {fontSize: 13}
                                                                        }}
                                                                        error={!!errors[attribute[0]]}
                                                                        helperText={errors[attribute[0]]}
                                                                    />

                                                                    {/* </Stack> */}
                                                                    {/* </LocalizationProvider> */}
                                                                </FormControl>
                                                                // eslint-disable-next-line no-nested-ternary
                                                                : attribute[1].type === "time" ?
                                                                    <FormControl variant="outlined"
                                                                                 key={index}
                                                                        // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                                                 sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                                    >
                                                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <Stack spacing={3}>
                                                                    <TimePicker
                                                                        name={attribute[0]}
                                                                        label={attribute[0]}
                                                                        value={data[attribute[0]]}
                                                                        onChange={(value) => handleTimeChange(attribute[0], value)}
                                                                        renderInput={(params) =>
                                                                            <TextField {...params} />}
                                                                        inputProps={{style: {fontSize: 13}}}
                                                                        InputLabelProps={{ style: {fontSize: 13}}}
                                                                        error={!!errors[attribute[0]]}
                                                                        helperText={errors[attribute[0]]}
                                                                    />
                                                                </Stack>
                                                            </LocalizationProvider> */}

                                                                        <TextField
                                                                            className={"time-field"}
                                                                            name={attribute[0]}
                                                                            id={attribute[0]}
                                                                            label={attribute[0]}
                                                                            type="time"
                                                                            // defaultValue=""
                                                                            value={data[attribute[0]]}
                                                                            onChange={(e) => handleTimeChange(e)}
                                                                            inputProps={{style: {fontSize: 13}}}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                                style: {fontSize: 13}
                                                                            }}
                                                                            error={!!errors[attribute[0]]}
                                                                            helperText={errors[attribute[0]]}
                                                                        />

                                                                    </FormControl>
                                                                    // eslint-disable-next-line no-nested-ternary
                                                                    : attribute[1].type === "integer" ?
                                                                        attribute[0] === "year" ?
                                                                            <TextField
                                                                                label={attribute[0]}
                                                                                type="number"
                                                                                name={attribute[0]}
                                                                                // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                                                sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                                                onInput = {(e) =>{
                                                                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                                                                                }}
                                                                                key={index}
                                                                                inputProps={{
                                                                                    style: {fontSize: 13},
                                                                                    inputMode: 'numeric',
                                                                                    pattern: '[0-9]*'
                                                                                }}
                                                                                InputLabelProps={{style: {fontSize: 13}}}
                                                                                value={data[attribute[0]]}
                                                                                onChange={onChange}
                                                                                error={!!errors[attribute[0]]}
                                                                                helperText={errors[attribute[0]]}
                                                                            />
                                                                            :
                                                                        <TextField
                                                                            label={attribute[0]}
                                                                            type="number"
                                                                            name={attribute[0]}
                                                                            // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                                            sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                                            variant={"outlined"}
                                                                            key={index}
                                                                            inputProps={{
                                                                                style: {fontSize: 13},
                                                                                inputMode: 'numeric',
                                                                                pattern: '[0-9]*'
                                                                            }}
                                                                            InputLabelProps={{style: {fontSize: 13}}}
                                                                            value={data[attribute[0]]}
                                                                            onChange={onChange}
                                                                            error={!!errors[attribute[0]]}
                                                                            helperText={errors[attribute[0]]}
                                                                        />
                                                                        // eslint-disable-next-line no-nested-ternary
                                                                        : attribute[1].type === "password" ?
                                                                            <TextField
                                                                                name={attribute[0]}
                                                                                label={attribute[0]}
                                                                                type="password"
                                                                                key={index}
                                                                                sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                                                autoComplete="current-password"
                                                                                inputProps={{style: {fontSize: 13}}}
                                                                                InputLabelProps={{style: {fontSize: 13}}}
                                                                                value={data[attribute[0]]}
                                                                                onChange={onChange}
                                                                                error={!!errors[attribute[0]]}
                                                                                helperText={errors[attribute[0]]}
                                                                            />
                                                                            // eslint-disable-next-line no-nested-ternary
                                                                            : attribute[1].type === "boolean" ? ""
                                                                                // <Switch name={attribute[0]}
                                                                                //         key={index}
                                                                                //         // value={data.Switch}
                                                                                //         onChange={onChange}
                                                                                //         error={!!errors.Switch}
                                                                                //         helperText={errors.Switch}
                                                                                //         {...label}
                                                                                //         defaultChecked/>
                                                                                : attribute[1].type === "media" ?
                                                                                    ""
                                                                                    :
                                                                                    <TextField
                                                                                        label={attribute[0]}
                                                                                        multiline
                                                                                        rows={4}
                                                                                        type={"text"}
                                                                                        name={attribute[0]}
                                                                                        // sx={{m: 1, width: 'calc(100% - 16px)'}}
                                                                                        sx={{m: 1, width: 'calc(50% - 16px)'}}
                                                                                        variant={"outlined"}
                                                                                        key={index}
                                                                                        inputProps={{style: {fontSize: 13}}}
                                                                                        InputLabelProps={{style: {fontSize: 13}}}
                                                                                        value={data[attribute[0]]}
                                                                                        onChange={onChange}
                                                                                        error={!!errors[attribute[0]]}
                                                                                        helperText={errors[attribute[0]]}
                                                                                    />

                                            ))}


                                            <div style={{width: "100%", marginTop: "20px"}} className={"con-left"}>
                                                <FormControl sx={{m: 1, width: '180px'}}>
                                                    <Button onClick={onCreateSubmit} type="submit"
                                                            variant="contained">
                                                        {
                                                            // eslint-disable-next-line no-nested-ternary
                                                            loading === true ? <CircularProgress size={"1.2rem"}
                                                                                                 style={{color: "#fff"}}/> : mode === "edit" ? "Update" : "Add"
                                                        }
                                                    </Button>
                                                </FormControl>
                                            </div>

                                            {/* <FormControl sx={{m: 1, width: 'calc(50% - 16px)'}} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    // value={values.weight}
                                    // onChange={handleChange('weight')}
                                    endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText>
                            </FormControl> */}
                                        </ThemeProvider>
                                    </fieldset>
                                </div>
                            </Box> : ""}

                        {mode === "view" ?
                            <>

                                {loading === true ?
                                    <div style={{width: "100%"}} className={"con-mid"}>
                                        <CircularProgress size={"2rem"}/>
                                    </div> :


                                    <div className={"row dash-data-holder"}>
                                        {/* <Button className={"col vehicle-block"} component={RouterLink}
                                                 to={`/dashboard/${slug}/new`}>
                                            <div className={"con-mid"}
                                                 style={{
                                                     margin: "none",
                                                     padding: "none",
                                                     width: "100%",
                                                     height: "100%"
                                                 }}>
                                                + Add Vehicle
                                            </div>
                                        </Button> */}
                                        <div className={"profile-data"}>
                                            <h5>Details</h5>
                                            <p>
                                                <span className={"details-title"}>Email</span><br/>{userData.email}
                                            </p>
                                            <p>
                                                <span
                                                    className={"details-title"}>Mobile</span><br/>{
                                                !userData.mobile_number ?  <button className={"details-title details-title-btn"}
                                                                                   onClick={toSettings}>Add</button> : userData.mobile_number
                                            }
                                            </p>
                                            <p>
                                                <span className={"details-title"}>Address</span><br/>{
                                                !userData.address ?  <button className={"details-title details-title-btn"}
                                                                             onClick={toSettings}>Add</button> : userData.address
                                            }
                                            </p>
                                        </div>

                                        <div className={"col"}>
                                            <div className={"vehicle-col"}>
                                                <h5 style={{color: "#fff", marginBottom: "20px"}}>Vehicles</h5>
                                                <Button className={"col dash-bookings-blocks"} component={RouterLink}
                                                        ref={(node) =>
                                                            node?.style.setProperty("min-width", "189px", "important")
                                                        }
                                                        to={`/dashboard/${slug}/new`}>
                                                    <div className={"con-mid"}
                                                         style={{
                                                             margin: "none",
                                                             padding: "none",
                                                             width: "100%",
                                                             height: "100%"
                                                         }}>
                                                        + Add Vehicle
                                                    </div>
                                                </Button>
                                                {/* <hr style={{border: "1px dashed rgba(255, 255, 255, .3)"}}/> */}
                                                {filteredUsers.map((row) => {
                                                    const {id, number, brand, model} = row;
                                                    const isItemSelected = selected.indexOf(id) !== -1;
                                                    return (
                                                        <div id={`tr_${id}`}
                                                             className={"col dash-bookings-blocks"}
                                                             // ref={(node) =>
                                                             //     node?.style.setProperty("min-width", "calc(189px)", "important")
                                                             // }
                                                             key={id}>
                                                            <div className={"row"}>
                                                                <div className={"col con-left-2"}
                                                                     style={{paddingLeft: "30px"}}>
                                                                    {`${brand} - ${model}`}
                                                                    <p
                                                                        style={{
                                                                            fontSize: "12px",
                                                                            color: "rgb(171,171,171)",
                                                                            padding: "5px 0 0",
                                                                            margin: "0"
                                                                        }}
                                                                    >{`Vehicle no: ${number}`}</p>
                                                                    <br/>
                                                                    <span onClick={() => onOpen(number)}
                                                                          className={"records-btn"}>Records</span>
                                                                </div>
                                                                <div className={"col con-right"}>
                                                                    <UserMoreMenu fieldId={{id, api}}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <div className={"col"}>
                                            {currentVehicle === "" ? "" :
                                                <span className={"details-title"}>Vehicle</span>}
                                            <h5 style={{color: "#fff"}}>
                                                {
                                                    currentVehicle === "" ? "Upcoming" : currentVehicle
                                                }
                                            </h5>
                                            {currentVehicle === "" ? "" :
                                                <button className={"details-title details-title-btn"}
                                                        onClick={backToUpcoming}>Back</button>}

                                            {/* vehicleEmpty === true ? <p className={"con-mid"} style={{color: "#fff", paddingTop: "40px"}}>No records found</p> : "" */}

                                            {currentVehicle === "" ?
                                                <BookingsList/> :
                                                bookings.map((booking, index) => (

                                                    <div key={`m_${index}`}>
                                                        {/*
                                                            vehicleEmpty === true ?
                                                                <div key={`s_${index}`} style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    color: "#fff"
                                                                }}
                                                                     className={"con-mid"}>No
                                                                    records
                                                                    found</div> : ""
                                                        */}

                                                        {
                                                            booking.vehicle === currentVehicle ?

                                                                <div className={"dash-bookings-blocks"} key={index}
                                                                     id={`booking_${index}`}>

                                                                    <div key={`2_ ${index}`} className={"row"}>
                                                                        <div className={"col"}>
                                                                            {`${booking.status} - `}
                                                                            <span
                                                                                className={"dash-booking-type"}>{`${booking.name}`}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div key={`3_ ${index}`} className={"row"}
                                                                         style={{marginTop: '7px'}}>
                                                                        <div className={"col"}>
                                                                            {booking.start_time.split('T')[0]}
                                                                            &emsp;|&emsp;
                                                                            {`${booking.start_time.split('T')[1].split('.')[0]} - ${booking.end_time.split('T')[1].split('.')[0]}`}
                                                                        </div>
                                                                    </div>

                                                                </div> : ""
                                                        } </div>

                                                ))
                                            }

                                        </div>

                                        {isUserNotFound && (
                                            ""
                                        )}

                                    </div>

                                }


                            </> : ""}


                    </Card>
                </Container>
            </Page>
        </>
    );
}
