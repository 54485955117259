import * as React from 'react';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
// material
import {CircularProgress, LinearProgress,} from '@mui/material';
// components
// mock
import SettingsActions from "../actions/SettingsActions";
import UserActions from "../actions/UserActions";


export default function BookingsList({component, api, mode}) {

    const [curtain, setCurtain] = useState(true);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [checkNull, setNull] = useState(false);

    const [APILoading, setAPILoading] = useState(true);

    const [bookings, setBookings] = useState([]);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [loading, setLoading] = useState(false);

    // const role = "admin";
    const [role, setRole] = useState();
    const navigate = useNavigate();

    const location = useLocation();

    const [slug, setSlug] = useState();

    const [page, setPage] = useState(0);

    const [title, setTitle] = useState("");

    const [attributes, setAttributes] = useState([]);


    // eslint-disable-next-line
    const [schemaArray, setSchemaArray] = useState([]);


    useEffect(() => {
        SettingsActions.me().then(async userRes => {
            setRole(userRes.data.roleName);
            setCurtain(false);
            if (userRes.data.roleName === "admin") {
                navigate('/dashboard/inventory', {replace: true});
            }

            await setUserName(userRes.data.first_name + " " + userRes.data.last_name);
            await setUserEmail(userRes.data.email);

            await UserActions.fetchBookings(userRes.data.email).then(async resData => {
                let events = resData.data.collection;
                for (let i = 0; i < events.length; i++) {
                    await UserActions.fetchBooking(events[i].uri).then(async res2 => {
                        events[i].vehicle = res2.data.collection[0].questions_and_answers[0].answer;
                    }).catch(err2 => {
                        console.log(err2);
                    })
                    if (i === events.length - 1) {
                        setAPILoading(false);
                        setBookings(events);
                    }
                }

                if (events.length === 0) {
                    setNull(true);
                } else {
                    setNull(false);
                }
            }).catch(err => {
                console.log(err);
            })

        }).catch(userErr => {
            console.log(userErr);
        })


    }, [])


    return (
        <>
            {/* <DashboardNavbar onOpenSidebar={() => setOpen(true)}/> */}
            <style>{
                `body {
                    background-color: rgb(16, 16, 16) !important;
                    color: #fff;
                 }
                 .dash-card {
                    box-shadow: none;
                 }
                 .more-dots {
                    color: #fff;
                 }
                 .dash-nav-2 {
                    background-color: rgba(16, 16, 16, .5);
                 }
                 .nav-username {
                    color: #fff;
                 }
                `}</style>
            {/*<Page title={title} className={"user-dash-page"}>*/}
            <div>
                {
                    loading === true && curtain === false ?
                        <div className={"loading-bar"}>
                            <LinearProgress/>
                        </div> : ""
                }
                {
                    curtain === true ?
                        <div className={"curtain con-mid"}>
                            <div style={{display: "inline-block"}}>
                                <CircularProgress size={"1rem"} style={{color: "#fff"}}/>
                                <span>
                            Loading
                        </span>
                            </div>
                        </div> : ""
                }
                {/*<Container className={"user-dash-container"}>*/}
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            <RouterLink to={"/dashboard/vehicle"} className={"dash-secondary-tab"}>My
                                vehicles</RouterLink>
                            <RouterLink to={"/dashboard/bookings"} className={"dash-primary-tab"}>▶ &nbsp;&nbsp;My
                                bookings</RouterLink>
                        </Typography>

                        <Button className={"dash-booking-btn dash-booking-btn-control"} variant="contained"
                                startIcon={<Iconify icon="eva:plus-fill"/>}
                                component={RouterLink} to={`/user/bookings-vehicle?email=${userEmail}&name=${userName}`}
                            // onClick={handleOpen}
                        >
                            New booking
                        </Button>

                    </Stack> */}

                {/* <div className={"container mobile-booking-btn"}>
                        <Button className={"dash-booking-btn"} style={{width: "100%"}} variant="contained"
                                startIcon={<Iconify icon="eva:plus-fill"/>}
                                component={RouterLink} to={`/user/bookings-vehicle?email=${userEmail}&name=${userName}`}
                            // onClick={handleOpen}
                        >
                            New booking
                        </Button>
                    </div> */}

                <div style={{paddingTop: "15px"}}>
                    {checkNull === true ? (
                        <div className={"container con-mid"} style={{marginTop: "100px", color: "#fff"}}>No bookings
                            found</div>) : ""}
                    {APILoading === true ? (
                            checkNull === true ? "" :
                                <div className="con-mid" style={{marginTop: "100px", color: "#fff"}}>Loading...</div>) :
                        bookings.map((booking, index) => (
                            <div className={"dash-bookings-blocks"} key={index}
                                 id={`booking_${index}`}>

                                <div key={`2_ ${index}`} className={"row"}>
                                    <div className={"col"}>
                                        {`Vehicle: ${booking.vehicle} - `}
                                        <span
                                            className={"dash-booking-type"}>{`${booking.name}`}</span>
                                    </div>
                                </div>
                                <div key={`3_ ${index}`} className={"row"}
                                     style={{marginTop: '7px'}}>
                                    <div className={"col"}>
                                        {booking.start_time.split('T')[0]}
                                        &emsp;|&emsp;
                                        {`${booking.start_time.split('T')[1].split('.')[0]} - ${booking.end_time.split('T')[1].split('.')[0]}`}
                                    </div>
                                </div>

                                {/* <div className={"dash-bookings-blocks"} key={index} id={`booking_${index}`}>


                                    <div className={"row bookings-pc-row"}>
                                        <div className={"col"}>
                                            <span
                                                className={"booking-vehicle-span"}>&emsp;{`Vehicle: ${booking.vehicle}`}</span>
                                            <span className={"dash-booking-type"}>{`${booking.name}`}</span>
                                        </div>

                                        <div className={"col"}>
                                            {booking.start_time.split('T')[0]}
                                        </div>

                                        <div className={"col"}>
                                            {`${booking.start_time.split('T')[1].split('.')[0]} - ${booking.end_time.split('T')[1].split('.')[0]}`}
                                        </div>
                                    </div>

                                    <div className={"bookings-mob-row container"}>
                                        <div className={"row"}>
                                            <span className={"dash-booking-type"}>{`${booking.name}`}</span>
                                            <span
                                                className={"booking-vehicle-span"}>&emsp;{`Vehicle: ${booking.vehicle}`}</span>
                                        </div>

                                        <div className={"row"} style={{marginTop: "12px"}}>
                                            <div>
                                                {booking.start_time.split('T')[0]}
                                            </div>
                                            <span>&nbsp;|&nbsp;</span>
                                            <div>
                                                {`${booking.start_time.split('T')[1].split('.')[0]} - ${booking.end_time.split('T')[1].split('.')[0]}`}
                                            </div>
                                        </div>
                                    </div>

                                </div> */}
                            </div>
                        ))
                    }
                </div>

                {/*</Container>*/}
                {/*</Page>*/}
            </div>
        </>
    );
}
