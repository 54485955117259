import axios from 'axios';
import Keys from "../config/Keys";
import authHeader from "../config/AuthHeader";

const {API_URL} = Keys;

class SettingsActions {
    // eslint-disable-next-line class-methods-use-this
    me() {
        return axios.get(`${API_URL}/api/users/me`, {headers: authHeader()});
    }

    // eslint-disable-next-line class-methods-use-this
    updateUser(id, data) {
        console.log(data);
        return axios.put(`${API_URL}/api/users/${id}`, data, {headers: authHeader()});
    }

    // eslint-disable-next-line class-methods-use-this
    changePassword(id, data) {
        return axios.post(`${API_URL}/api/auth/change-password`, data, {headers: authHeader()})
    }

}

export default new SettingsActions();