import {useState} from 'react';
import {Link as RouterLink, useNavigate} from "react-router-dom";
// form
// @mui
import {Link, IconButton, InputAdornment, Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import AuthActions from "../../../actions/AuthActions";

// ----------------------------------------------------------------------

export default function LoginForm() {

    const navigate = useNavigate();

    const schema = {
        identifier: "",
        password: ""
    }

    const [loading, setLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [state, setState] = useState(schema);
    const [errors, setErrors] = useState(schema);

    const onChange = e => {
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };

    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setErrors(schema);

        AuthActions.LoginUser(state).then((res) => {
            setLoading(false);
            localStorage.setItem("jwt", JSON.stringify(res.data.jwt));
            navigate('/dashboard/vehicle', {replace: true});
        }).catch(err => {
            setLoading(false);

            if (err.message === "Request failed with status code 429") {
                console.log("Rate limit exceeded");
            }

            const errorResponseMsg = err.response.data.error.message;
            const errorResponse = err.response.data.error.details.errors;

            let tempErrors = {};


            if (errorResponseMsg === "Invalid identifier or password") {
                tempErrors = Object.assign(tempErrors, {identifier: "Invalid email or password"});
                tempErrors = Object.assign(tempErrors, {password: " "});
                setErrors(tempErrors);
            } else if (errorResponseMsg === "Your account email is not confirmed") {
                tempErrors = Object.assign(tempErrors, {identifier: "Your account email is not confirmed"});
                setErrors(tempErrors);
                navigate(`/confirmation?email=${state.identifier}`);

            } else {

                const updateError = (item, i) => {

                    if (item.path[0] === "identifier" && item.message === "identifier is a required field") {
                        tempErrors = Object.assign(tempErrors, {identifier: "email is a required field"});
                    } else {
                        const data0 = {[item.path[0]]: item.message};
                        tempErrors = Object.assign(tempErrors, data0);
                    }

                    if (i === errorResponse.length - 1) {
                        setErrors(tempErrors);
                    }


                }

                errorResponse.forEach(updateError)
            }

        })
    };


    return (
        <form onSubmit={onSubmit}>
            <Stack spacing={3}>
                <TextField name="identifier" id={"identifier"} label="Email address"
                           onChange={onChange}
                           value={state.identifier}
                           error={!!errors.identifier}
                           helperText={errors.identifier}
                           inputProps={{style: {fontSize: 13}}}
                           InputLabelProps={{style: {fontSize: 13}}}
                />

                <TextField
                    name="password"
                    id={"password"}
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        style: {fontSize: 13},
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={onChange}
                    value={state.password}
                    error={!!errors.password}
                    helperText={errors.password}
                    InputLabelProps={{style: {fontSize: 13}}}
                />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
                <Link to={"/forgot"} variant="subtitle2" component={RouterLink}>
                     <span style={{fontSize: "12px", color: "#000"}}>Forgot password?</span>
                </Link>
            </Stack>

            <LoadingButton fullWidth loading={loading} size="large" type="submit" variant="contained" className={"auth-btn"}>
                Sign in
            </LoadingButton>
        </form>
    );
}
