import {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// material
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
// component
import PropTypes from "prop-types";
import Iconify from '../../../components/Iconify';
import UserActions from "../../../actions/UserActions";

// ----------------------------------------------------------------------

UserMoreMenu.propTypes = {
    fieldId: PropTypes.object
};

export default function UserMoreMenu({fieldId}) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const deleteData = () => {
        UserActions.deleteData(fieldId.id, fieldId.api).then((res) => {
            console.log(res.data);
            setIsOpen(false);
            document.getElementById(`tr_${fieldId.id}`).style.display = "none";
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" className={"more-dots"} width={20} height={20}/>
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: {width: 200, maxWidth: '100%'},
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <MenuItem sx={{color: 'text.secondary'}} onClick={deleteData}>
                    <ListItemIcon>
                        <Iconify icon="eva:trash-2-outline" width={24} height={24}/>
                    </ListItemIcon>
                    <ListItemText primary="Delete" primaryTypographyProps={{variant: 'body2'}}/>
                </MenuItem>


                {fieldId.api !== 'users' ?
                    <MenuItem component={RouterLink} to={
                        fieldId.custom === 'user-vehicle' ? `../vehicle-admin/edit/${fieldId.id}` :
                        `./edit/${fieldId.id}`
                    } sx={{color: 'text.secondary'}}>
                        <ListItemIcon>
                            <Iconify icon="eva:edit-fill" width={24} height={24}/>
                        </ListItemIcon>
                        <ListItemText primary="Update" primaryTypographyProps={{variant: 'body2'}}/>
                    </MenuItem> : ""}


            </Menu>
        </>
    );
}
